module.exports = (options) => {
    let settings = {
        c: 2,	//decimals 2 is default
        t: ".",			//thousands devider
        d: ",",			//decimal devider
        method: 'toMoney'
    };
    const methods = {
        toMoney: function (n) {
            const c = settings.c,
                d = settings.d,
                t = settings.t;
            const s = n < 0 ? "-" : "",
                i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c)));
            const j = i.length > 3 ? i % 3 : 0;
            if (d === t) {
                throw("ERROR: Decimal and thousands separator must be different.");
            }
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
        },
        toNumber: function (s) {
            const d = settings.d,
                t = settings.t;
            if (d === t) {
                throw("ERROR: Decimal and thousands separator must be different.");
            }
            if (t === ".") {
                s = s.replace(/\./g, "");
            } else {
                s = s.replace(/'/g, "");
            }
            if (d !== ".") {
                s = s.replace(/,/g, ".");
            }
            return Number(s);
        }
    };

    if (typeof options === 'string') {
        return methods['toNumber'](options);
    } else if (options !== undefined && typeof options === 'object') {
        settings = {...settings, ...options};
        return methods[settings.method](settings.value);
    } else if (typeof options === 'number') {
        return methods['toMoney'](options);
    }
    return null;
}
