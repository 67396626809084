<template>
  <tr :key="'tr'+index" :class="isSelected?'secondary':''" @click="selectRow">
    <td v-for="head in headers" :key="index + head.key +'rtd'">
      <v-simple-checkbox v-if="head.type === 'checkbox'"
                         :indeterminate="item[head.value]===null || item[head.value]===undefined"
                         :value="item[head.value]" disabled></v-simple-checkbox>
      <template v-else-if="head.value === 'actions'">
        <template v-if="campaignActionButtons && campaignActionButtons.length > 0">
          <v-chip-group
              v-model="activeCAction"
              active-class="primary--text"
              mandatory
          >
            <v-chip
                v-for="btn in doneUndoneButtons"
                :key="btn.action"
                :active-class="btn.action === 'campaignDone' ? 'secondary' : 'accent'"
                :disabled="loading"
                @click="campaignAction(item,btn.action)"
                x-small
            >
              {{ btn.label }}
            </v-chip>
          </v-chip-group>
        </template>
        <template v-if="campaignActionButtons && campaignActionButtons.length > 0">
          <v-chip
              x-small
              @click="campaignAction(item,'openComments')"
          >
            Bemerkungen
          </v-chip>
          <v-chip
              v-for="btn in otherButtons"
              :key="btn.action"
              :disabled="loading"
              x-small
              @click="campaignAction(item,btn.action)"
          >
            {{ btn.label }}
          </v-chip>
        </template>
        <template v-else>
          <v-icon v-show="!actionEditDisabled && !readOnly && !tableRowsReadOnly" class="mr-2" small @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon v-show="(!actionEditDisabled && readOnly) || (actionEditDisabled && tableRowsReadOnly)" class="mr-2" small @click="editItem(item)">
            mdi-eye
          </v-icon>
          <!-- !isRoleScoring && -->
          <v-icon v-show="!actionDeleteDisabled && !readOnly && !tableRowsReadOnly" small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>

      </template>

      <v-icon v-else-if="head.display === 'threeState'">
        {{ threeStateIcon(item[head.value]) }}
      </v-icon>
      <span v-else :style="head.type === 'money' ? 'text-align: right;display:block;':''">
<!--        {{head.text.type === 'checkbox' ? "" : item[head.value] }}-->
        {{ item[head.value] }}
        <!--        {{JSON.stringify(head)}}-->
      </span>
      <v-icon v-if="head.value==='data-table-select' && isSelected && !readOnly">check_box</v-icon>
      <v-icon v-if="head.value==='data-table-select' && !isSelected && !readOnly">check_box_outline_blank</v-icon>
    </td>
  </tr>
</template>
<script>
import threeStateIcon from "@/helpers/threeStateIcon";

export default {
  props: {
    readOnly: {
      required: true,
      type: Boolean
    },
    item: {
      required: true,
      type: Object
    },
    headers: {
      required: true,
      type: Array
    },
    index: {
      required: true,
      type: Number
    },
    actionEditDisabled: {
      required: false,
      type: Boolean
    },
    tableRowsReadOnly: {
      required: false,
      type: Boolean
    },
    actionUnlinkDisabled: {
      required: false,
      type: Boolean
    },
    actionDeleteDisabled: {
      required: false,
      type: Boolean
    },
    isSelected: {
      required: false,
      type: Boolean
    },
    campaignActionButtons: {
      required: false,
      type: Array
    },
    campaignDone: {
      required: false,
      type: Boolean
    }
  },
  name: 'TableRow',
  data() {
    return {
      selected: false,
      activeCAction: this.$props.campaignDone ? 0 : 1,
      loading: false,
      isRoleScoring: false,
    };
  },
  created() {
    if (localStorage.getItem('role') == 'scoring') {
      this.isRoleScoring = true
    } else {
      this.isRoleScoring = false
    }
  },
  computed: {
    doneUndoneButtons() {
      return this.$props.campaignActionButtons.filter(x => x.group === 'doneUndone')
    },
    otherButtons() {
      return this.$props.campaignActionButtons.filter(x => x.group !== 'doneUndone' && this.$props.campaignDone)
    }
  },
  methods: {
    selectRow() {
      this.$emit('rowClick');
    },
    editItem(item) {
      this.$emit('editItem', item);
    },
    deleteItem(item) {
      this.$emit('deleteItem', item);
    },
    campaignAction(item, action) {
      this.loading = true;
      this.$emit('campaignAction', item, action, () => {
        this.loading = false;
      });
    },
    threeStateIcon(state) {
      return threeStateIcon(state);
    },
  }
}
</script>
