import helpers from "@/helpers/functions";

export default (editedItem,model) => {
  for(let field in editedItem){
    if (model[field] && model[field].type === 'datetime') {
      //editedItem[field] = helpers.getDatePickerString(editedItem[field],true);
    } else if (model[field] && model[field].type === 'date') {
      editedItem[field] = helpers.getDatePickerString(editedItem[field]);
    }
    if (model[field] && model[field].selectListFilter !== undefined) {
      const fieldModel = model[field];
      editedItem[field].selectListFilter = {name: fieldModel.filtered, value: editedItem[fieldModel.filtered]};
    }

    if (model[field] && model[field].dontSave === true) {
      delete editedItem[field];
    }

    //referenced fields
    if (typeof editedItem[field] === 'object' && editedItem[field] !== null && model[field] !== undefined && model[field].reference && model[field].content !== undefined) {
      editedItem[field] = editedItem[field][model[field].content];
    }
  }
}
