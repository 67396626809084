<template>
  <v-card class="mx-auto">
    <v-card-text>
      <h1>{{listTitle}}</h1>
    </v-card-text>


    <v-card>
      <v-tabs v-model="tab" class="px-4" background-color="primary" dark>
        <v-tabs-slider color="accent"></v-tabs-slider>
        <v-tab v-for="item in sublists" :key="item.name">
          {{ item.label }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in sublists" :key="item.name">
          <v-card flat>
            <v-card-text>
              <Sublist v-if="item.type==='list'" :listName="item.name" :listTitle="item.label" :model="item"
                       :parent="{}"/>
              <InvoiceEditor v-if="item.type==='invoiceTemplate'"/>
              <StartPageeditor v-if="item.name==='homeHtml'"/>
            </v-card-text>
          </v-card>
        </v-tab-item>

      </v-tabs-items>
    </v-card>


  </v-card>
</template>
<script>
import Sublist from './Sublist';
import InvoiceEditor from "@/components/Forms/InvoiceEditor";


export default {
  components: {
    InvoiceEditor,
    Sublist,
    StartPageeditor: () => import('@/components/Forms/StartPageEditor'),
  },
  name: 'TabLists',
  props: {
    listTitle:{
      type: String,
      required: true
    },
    sublists:{
        type: Array,
        required: true
      }
  },
  data () {
    return {
      tab: undefined,
      startPage: {json: {}}
    };
  }
}

</script>
