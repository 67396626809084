<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12 sm6 offset-sm3 mt-3>
        <h1>Bitte melden Sie sich an.</h1>

          <v-flex class="text-xs-center" mt-5>
            <v-alert
              type="error"
            >Sie sind nicht angemeldet</v-alert>
            <v-btn to="/signin"
            depressed
            color="primary"
            >Anmelden</v-btn>
          </v-flex>
      </v-flex>
    </v-layout>
  </v-container>

</template>
<script>

export default {
  name: 'Forbidden',
  data () {
    return {};
  }
}
</script>
