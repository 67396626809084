<template>
  <v-dialog
    v-model="filterDialog"
    scrollable
    max-width="900px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          class="mx-1"
        color="grey"
        dark
        fab
        small
        elevation="2"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon dark>
          mdi-filter
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Filter Editor
        <v-spacer></v-spacer>
        <SelectList
            v-model="filterTemplate"
            :filter="{type_eq:model.listName}"
            :model="getFilterModel"
            label="Filtervorlagen"
            listName="filters"
            @input="filterTemplateSelected"
        />
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="11">
            <FilterDisplay
                :filter="tempFilter"
                :list-model="model.model"
                :andAction="addFilter"
                :orAction="addFilter"
                :deleteAction="deleteFilter"
                :nextFilterOperant="nextFilterOperant"
                :filterIndex="filterIndex"
                :key="'updated'+helpKey"
                @chipClick="edit()"
               />
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" md="4">
            <v-select
              v-if="nextFilterOperant!==null || tempFilter.length === 0 || editFilter.length === 3"
              :items="filterFields"
              v-model="selectedFilterField"
              label="Spalte"
              item-text="name"
              item-value="value"
             />
           </v-col>
          <v-col cols="12" md="3">
            <v-select
                v-if="selectedFilterField && selectedFieldType !== 'checkbox' && (selectedFieldType !== 'date' && selectedFieldType !== 'datetime')"
                :items="[{name:'enthält',value:'contains'},{name:'enthält nicht',value:'ncontains'},{name:'ist gleich',value:'eq'},{name:'ist nicht gleich',value:'ne'},{name:'ist kleiner als',value:'lt'},{name:'ist größer als',value:'gt'}]"
                v-model="selectedFilterOperator"
                label="Operant"
                item-text="name"
                item-value="value"
            >
            </v-select>
            <v-select
                v-if="selectedFilterField && selectedFieldType === 'checkbox' && (selectedFieldType !== 'date' && selectedFieldType !== 'datetime')"
                v-model="selectedFilterOperator"
                :items="[{name:'ist gleich',value:'eq'},{name:'ist nicht gleich',value:'ne'}]"
                item-text="name"
                item-value="value"
                label="Operant"
            >
            </v-select>
            <v-select
                v-if="selectedFilterField && (selectedFieldType === 'date' || selectedFieldType === 'datetime')"
                :items="[{name:'ist gleich',value:'eq'},{name:'ist nicht gleich',value:'ne'},{name:'ist kleiner als',value:'lt'},{name:'ist größer als',value:'gt'}]"
                v-model="selectedFilterOperator"
                label="Operant"
                item-text="name"
                item-value="value"
            >
            </v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-menu v-if="selectedFilterField &&( selectedFieldType === 'date' || selectedFieldType === 'datetime')"
                    v-model="menu" :close-on-content-click="false" :nudge-bottom="20" :nudge-right="0" min-width="290px"
                    offset-y transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="filterText" label="" clearable prepend-inner-icon="mdi-calendar" v-bind="attrs"
                              v-on="on"></v-text-field>
              </template>
              <v-date-picker @input="menu = false" v-model="filterText" locale="de-de" :no-title="true"
                             :first-day-of-week="1"></v-date-picker>
            </v-menu>
            <v-checkbox v-else-if="selectedFilterField && selectedFieldType === 'checkbox'" v-model="filterText" label="Wahr oder Falsch" />
            <!--            <v-select v-else-if="selectedFilterField && selectedFieldType === 'checkbox'" v-model="filterText" label="Wahr, Falsch oder null" :items="[{text:'leer',value:null},{text:'wahr',value:true},{text:'falsch',value:false}]" />-->
            <v-text-field v-else-if="selectedFilterField" v-model="filterText" label="Text"></v-text-field>

          </v-col>
          <v-col cols="12" md="1">
            <v-btn color="accent" v-if="selectedFilterField" small icon  @click="addFilter">
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-flex v-if="error" class="text-xs-center" mt-5>
          <v-alert style="white-space:pre-wrap" type="error">{{error}}</v-alert>
        </v-flex>
        <v-spacer />
        <v-btn
          color="danger"
          text
          @click="tempFilter = [];filterIndex=-1;applyfilter()"
          v-if="tempFilter.length > 0 || filter.length > 0"
        >
          Alle Filter entfernen
        </v-btn>
        <v-btn
            color="secondary"
            text
            @click="filterDialog = false"
        >
          Abbrechen
        </v-btn>
        <v-btn
            v-if="nextFilterOperant === null && tempFilter.length > 0"
            color="danger"
            text
            @click="openSaveFilterDialog"
        >
          Als Vorlage speichern
        </v-btn>
        <v-btn
            color="accent"
            dark
            @click="applyfilter"
            v-if="nextFilterOperant === null && tempFilter.length > 0"
        >
          Anwenden
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="saveFilterDialog" max-width="500" overlay-opacity="1">
      <v-card-text>
        <FilterDisplay
            :key="'updated'+helpKey"
            :filter="tempFilter"
            :list-model="model.model"
            @chipClick="edit()"
        />


        <EditForm ref="saveFilterForm" v-model="saveFilterItem" :model="saveFilterModel"
                  @validation="validate"></EditForm>

        <v-card-actions>
          <v-flex v-if="error" class="text-xs-center" mt-5>
            <v-alert style="white-space:pre-wrap" type="error">{{ error }}</v-alert>
          </v-flex>
          <v-spacer/>

          <v-btn
              color="secondary"
              text
              @click="saveFilterDialog = false"
          >
            Abbrechen
          </v-btn>

          <SaveButton :disabled="!saveFilterFormIsValid || loading"
                      :edited-index="-1" :edited-item="saveFilterItem"
                      :list-model="saveFilterModel" :route-model="filterRouteModel"
                      @failed="failed" @success="success"></SaveButton>
        </v-card-actions>
      </v-card-text>
    </v-dialog>

  </v-dialog>
</template>
<script>
import FilterDisplay from './FilterDisplay';
import makeFieldDisplayName from "@/helpers/makeFieldDisplayName";


export default {
  components: {
    EditForm: () => import('@/components/Forms/EditForm'),
    SaveButton: () => import('@/components/functions/SaveButton'),
    SelectList: () => import('@/components/Forms/SelectList'),
    FilterDisplay
  },
  props: [
    "model",
    "editFilter"
  ],
  name: 'FilterEditor',
  data () {
    return {
      helpKey: 0,
      menu: false,
      filter: [],
      filterTemplate: null,
      tempFilter: [],
      nextFilterOperant: null,
      filterIndex: -1,
      loading: false,
      filterText: '',
      saveFilterItem: {
        name: null,
        type: null,
        query: null
      },
      getFilterModel: {
        id: false,
        long: 'name',
        short: 'name',
        value: 'query',
        name: {
          label: "Filtername",
          required: true
        },
        type: {
          editable: false
        },
        query: {
          editable: false
        }
      },
      saveFilterModel: {
        id: false,
        name: {
          label: "Filtername",
          required: true
        },
        type: {
          editable: false
        },
        query: {
          editable: false
        }
      },
      saveFilterDialog: false,
      selectedFilterOperator: 'contains',
      selectedFilterField: null,
      filterDialog: false,
      disabled: [],
      error: null,
      saveFilterFormIsValid: false
    };
  },
  methods: {
    filterTemplateSelected(selected) {
      this.tempFilter = selected
    },
    validate(state) {
      this.saveFilterFormIsValid = state;
    },
    applyfilter() {
      this.filterDialog = false;
      this.filter = Object.assign([], this.tempFilter);
      this.$emit('input', this.filter);
    },
    openSaveFilterDialog() {
      this.saveFilterItem.type = this.$props.model.listName;
      this.saveFilterItem.query = this.tempFilter;
      this.saveFilterDialog = true;
    },
    failed(er) {
      this.error = er.message;
      this.loading = false;
    },
    success() {
      this.saveFilterDialog = false;
      this.loading = false;
    },
    deleteFilter(i) {
      this.tempFilter.splice(i, 1);
      this.disabled.splice(i, 1);
    },
    edit() {
      this.filterDialog = true;
    },
    addFilter(andOr, index) {

      /*
      should be like this
      _where: {
        _or: [
          [{ stars: 2 }, { pricing_lt: 80 }], // implicit AND
          [{ stars: 1 }, { 'categories.name': 'French' }], // implicit AND
        ],
      },
      */
      this.error = null;

      if(typeof andOr === 'string'){

        //set upcoming filter for and or or
        if(this.filterIndex !== -1){
          this.nextFilterOperant = null
          this.filterIndex = -1;
        }else{
          this.nextFilterOperant = andOr
          this.filterIndex = index===undefined? -1 : index;
        }
      }else{
        //addFilter

        let pusher = {};
        pusher[this.selectedFilterField + "_" + this.selectedFilterOperator] = this.filterText;
        let i = this.filterIndex===-1? Math.max(this.tempFilter.length-1,0) : this.filterIndex;
        if(this.nextFilterOperant === 'and'){
          //this.tempFilter[i] = this.tempFilter[i] === undefined ? [[pusher]] : this.tempFilter[i].concat([pusher]);
          if(this.disabled.length > 0 && this.disabled[i] !== undefined && this.disabled[i].indexOf(this.selectedFilterField + "_" + this.selectedFilterOperator)!==-1){
            this.error = 'Zur Zeit unterstützt der Filter nicht mehrere Und-Bedingungen für gleiche Feld-Vergleichs-Einheiten.'
          }else{
            this.tempFilter[i] = this.tempFilter[i] === undefined ? [[pusher]] : this.tempFilter[i].concat([pusher]);
          }
        }else{
          this.tempFilter.push([pusher]);
        }
        if(this.error === null){
          this.disabled[i] === undefined
              ?
              this.disabled[i] = [this.selectedFilterField + "_" + this.selectedFilterOperator]
              :
              this.disabled[i].push(this.selectedFilterField + "_" + this.selectedFilterOperator);
          this.nextFilterOperant = null;
          this.helpKey++;//for update triggers
          this.filterIndex = -1;
        }
      }
      if(this.error === null){
        this.showColSel = false;
        this.selectedFilterField = null;
      }
    },
  },
  watch:{
    selectedFieldType(){
      if(this.selectedFieldType === 'checkbox'){
          this.selectedFilterOperator = 'eq';
          this.filterText = false;
      }else{
        this.filterText = '';
        this.selectedFilterOperator = 'contains';
      }

    }
  },
  computed: {
    filterRouteModel() {
      return {
        listName: 'filter',
        listTitle: 'Filter',
        typeKey: 'Filter',
        mutationKey: 'filter',
        model: this.saveFilterModel
      }
    },
    selectedFieldType() {
      return this.selectedFilterField !== null ? typeof this.model.model[this.selectedFilterField] === "object" ? this.model.model[this.selectedFilterField].type || 'text' : 'text' : 'text';
    },
    filterC() {
      let fc = [];
      this.tempFilter.map(function (oF) {
        let ands = [];
        oF.map(function (aF) {
          for (let k in aF) {
            ands.push('<' + k.split('_')[0] + '> ' + k.split('_')[1] + ' \'' + aF[k] + '\'');
          }
        });
        fc.push(ands);
      });
      return fc;
    },
    filterFields() {
      let model = this.$props.model.model; //functions.searchFieldModels(this.$props.model);
      let fields = [];

      try {
        //let createVerein = this.editedIndex === -1;
        for (let field in model) {
          const fieldModels = Array.isArray(model[field]) ? model[field] : [model[field]];
          const modelFieldIsArray = Array.isArray(model[field]);
          fieldModels.map((fieldModel) => {
            if (typeof fieldModel === "string" || (typeof fieldModel === "boolean" && fieldModel) || typeof fieldModel === 'object' && fieldModel.filterAble === undefined || fieldModel.filterAble === true) {
              fields.push({
                value: modelFieldIsArray ? field + "-" + fieldModel.content : field,
                name: typeof fieldModel === "object" ? makeFieldDisplayName(field, fieldModel) : typeof fieldModel === 'boolean' ? field : fieldModel,
                disabled: this.disabled !== undefined && this.disabled.length === 0 ? false : this.disabled[this.filterIndex].indexOf(field + "_" + this.selectedFilterOperator) !== -1
              });
            }
          });
        }
      } catch (error) {
        console.log(error);
      }

      return fields;
    },
  }
}
</script>
