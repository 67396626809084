export default {
  fieldType(field) {
    if (typeof field === 'object') {
      if (field.type) {
        return field.type;
      }
    }
    return "text";
  },
  findValInObj(obj, key) {
    let seen = new Set, active = [obj];
    while (active.length) {
      let new_active = [], found = [];
      for (let i = 0; i < active.length; i++) {
        Object.keys(active[i]).forEach(function (k) {
          const x = active[i][k];
          if (k === key) {
            found.push(x);
          } else if (x && typeof x === "object" &&
              !seen.has(x)) {
            seen.add(x);
            new_active.push(x);
          }
        });
      }
      if (found.length) return found;
      active = new_active;
    }
    return null;
  },
  getLocalDateString(s,format){
    if (s === null || s === '' || s === undefined)
        return null;
    let formatO = format || {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };
    let d = new Date(s);
    if(s.indexOf(".") === -1 && !isNaN(d)){
      return d.toLocaleDateString('de-DE', formatO);
    }else if(!isNaN(new Date(s.split(".")[2]+'-'+s.split(".")[1] + '-' + s.split(".")[0]))){
      return new Date(s.split(".")[2]+'-'+s.split(".")[1] + '-' + s.split(".")[0]).toLocaleDateString('de-DE', formatO);
    }else {
      try {
        return d.toLocaleDateString('de-DE', formatO);
      } catch (er) {
        console.error(er);
        return s;
      }

    }
  },
  getDatePickerString(s,time){
    if(s === null){
      return null;
    }
    let d = new Date(s);
    if (typeof s === 'string' && s.indexOf(".") !== -1 && !isNaN(new Date(s.split(".")[2] + '-' + s.split(".")[1] + '-' + s.split(".")[0]))) {
      d = new Date(s.split(".")[2] + '-' + s.split(".")[1] + '-' + s.split(".")[0]);
    }
    if (isNaN(d)) {
      return null;
    } else {
      return time === undefined ? d.toISOString().split("T")[0] : d.toISOString().split("T")[0] + " " + d.toISOString().split("T")[1].substr(0, 5);
    }
  },
  cleanHeaders(headers){
    let newHeaders = {};
    for(let head in headers){
      let newKey = headers[head].value.replace(/\./g,'-');
      newHeaders[head] = {
        ...headers[head]
      }
      newHeaders[head].value = newKey;
    }
    return newHeaders;
  },
  searchFieldModels(model,headers){
    let sfModels = {};
    for(let head in headers){
      let key = headers[head].value;
      if(model[key] !== undefined){
        sfModels[key] = model[key];
      }else{
        if(model[key.split(".")[0]] !== undefined){
          const aKey = key.split(".")[0];
          const fieldName = key.split(".")[1];
          if(Array.isArray(model[aKey])){
            model[aKey].map((x)=>{
              if(x.content === fieldName){
                sfModels[aKey+'-'+fieldName] = x;
                sfModels[aKey] = x;
              }
            });
          }else{
            sfModels[aKey+'-'+fieldName] = model[aKey];
          }
        }
      }
    }
    return sfModels;
  },
}
