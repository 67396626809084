import gql from 'graphql-tag';

/*
query(){
    //return gqlCreator.query(this.$props.model.model,this.$props.listName);
},
variables() {
  return {where:this.where}
},
update (data) {
    return data[this.$props.listName];
},
*/

export default {
  modelToQF(model){
    const createQuery = (fields,fieldKey) => {
      let fieldString = '';
      for(let subKey in fields){
        let subField = fields[subKey];
        //model[field].type !== 'list' && model[field].type !== 'media' && model[field].type !== 'table' && (model[field].type !== 'select' || model[field].reference === false)
        if(typeof subField === 'object' && subField.model !== undefined && subField.model.query !== false){
          fieldString += createQuery(subField.model,subKey);
        }else if(subField.model === undefined || subField.model !== undefined && subField.model.query !== false){
          fieldString += subKey + '\n';
        }
      }
      if(fieldKey !== undefined){
        return `${fieldKey}{
            ${fieldString}
          }`;
      }
      return fieldString;
    }
    let modelFieldsCollection = {}; // collect same field names to group them into one query
    for (let field in model) {
      const modelFields = Array.isArray(model[field]) ? model[field] : [model[field]];
      modelFields.forEach((modelField) => {
        if(modelField.query !== false){
          if(modelFieldsCollection[field] === undefined && model[field].subList !== true){
            modelFieldsCollection[field] = modelField;
          }else if(model[field].subList !== true){
            modelFieldsCollection[field].model = {
              ...modelFieldsCollection[field].model,
              ...modelField.model
            };
          }
        }
      });
    }
    return createQuery(modelFieldsCollection);
  },
  upload(createMutation){
    return gql`mutation ($files: [Upload]!, $refId: ID!, $ref: String){
      ${createMutation}(
        refId: $refId
        ref: $ref
        files: $files
      ){
          id
          url
          name
      }
    }`
  },
  create(typeKey,mutationKey,model){
    return gql`mutation ($item: ${typeKey}Input) {
      create${typeKey}(
        input:{
          data: $item
        }){
        ${mutationKey} {
          ${this.modelToQF(model)}
        }
      }
    }`
  },
  query(model,listName, vars){
    if(typeof model === 'string'){
      //count
      return gql`query($where: JSON!){
        ${listName}Connection(where: $where){
          aggregate{
            count
          }
        }
      }`;
    }else{
      //actual query
      let qSpec = vars!==undefined?vars.qSpec:'$where: JSON!';
      let qVars = vars !== undefined ? vars.qVars : 'where: $where';
      const qs = this.modelToQF(model);
      // console.log(qs)
      // console.log('-------------------------------------------')
      // console.log('qSpec', qSpec)
      // console.log('listName', listName)
      // console.log('qVars', qVars)
      // console.log('qs', qs)
      // console.log('-------------------------------------------')

      // console.log(`query(${qSpec}){
      //   ${listName}(${qVars}){
      //   ${qs}
      // }
      // }`)
      const gqlOut = gql`query(${qSpec}){
        ${listName}(${qVars}){
        ${qs}
      }
      }`;
      // console.log({
      //   listName: listName,
      //   qSpec: qSpec,
      //   qVars: qVars,
      //   fields: queryFields||this.modelToQF(model)
      // });
      return gqlOut;
    }
  }
};
