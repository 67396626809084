import createRecords from "@/helpers/createRecords";
import gql from "graphql-tag";
import gqlCreator from "@/helpers/gqlCreator";
import helpers from "@/helpers/functions";
import newDataHelper from "@/helpers/newDataHelper";
import updateRecords from "@/helpers/updateRecords";

export default (that) => {
    that.loading = true;
    const updateProp = that.$props.update || {};
    const newData = updateProp.newData || newDataHelper(that.editedItem, that.routeModel, that.parent, that.originalItem);
    const failed = (er, info) => {
        er = {...er, ...info};
        that.failed(er);
    }

    const seperateDirectReferences = () => {
        let referenceJobs = [];
        for (let field in newData) {
            if (that.listModel && [field].subList) {
                delete newData[field];
            } else if (newData[field] !== null && typeof newData[field] === 'object' && that.listModel && that.listModel[field].reference && that.listModel[field].value !== "id") {
                let jobObject = {
                    list: field,
                    value: {...newData[field]},
                    typeKey: that.listModel[field].typeKey,
                    mutationKey: that.listModel[field].mutationKey,
                    id: newData[field].id
                };
                delete jobObject.value.id;
                delete jobObject.value.__typename;
                referenceJobs.push(jobObject);
                delete newData[field];
            }
        }
        return referenceJobs;
    }

    if (that.editedIndex > -1 || that.$props.update !== undefined) {

        //seperate referenced direct fields to update jobs
        const updateJobs = updateProp.updateJobs || seperateDirectReferences();
        const typeKey = updateProp.typeKey || that.routeModel.typeKey;
        const mutationKey = updateProp.mutationKey || that.routeModel.mutationKey;
        const editedItemID = updateProp.id || that.editedItemID || that.editedItem.id;
        
        that.$apollo.mutate({
        mutation: gql`mutation ($id: ID!, $item: edit${typeKey}Input) {
        update${typeKey}(
        input:{
        where: {id:$id}
        data: $item
        }){
        ${mutationKey} {
                id
            }
        }
        }`,
        variables:{
          id: editedItemID,//that.tableItems[that.editedIndex].id,
          item: newData
        }
      }).then(function(){
        //do updateJobs for direct referenced fields
            updateRecords(updateJobs, that).then(() => that.success(), (er) => {
                failed(er, {function: "updateRecords", job: updateJobs})
            });

      },(er)=>{failed(er, {function:"saveItemHelper",job:newData})});
    } else {
      //create record

      //seperate referenced direct fields to create jobs
      let createJobs = seperateDirectReferences();
      let tempCopy = {...newData};//Object.assign({},newData);
      let createMutation = that.routeModel.mutationKey;

      /*
      * {"confirmed":true,"blocked":false,"username":"testvereinsuser","email":"schnihils@me.com","password":"wiqvug-tyStiq-1wyzko","role":"61714b4101e9195a405b2342","golfvereine":"5fcf9eedd16bbba2b7ca8e7e"}
      * */


        /*const createRecords = async (jobs) => {
          return Promise.all(
              jobs.map((job) => {
                    try{
                      return createRecord(job)
                    }catch(er){
                      return er;
                    }
                  }
              )
          );
        };*/

      let apolloObject = {
        mutation: gqlCreator.create(that.routeModel.typeKey,createMutation,that.listModel),
        variables:{
          item: tempCopy
        }
      };
      if(createMutation === 'user'){
        const REGISTER_USER = gql`
          mutation createUser ($email: String! $password: String! $name: String! $role: ID! $golfvereine: ID){
            createUser(input:{data:{golfvereine: $golfvereine email: $email password: $password username: $name confirmed: true role: $role}}){
              user{id}
            }
          }
        `
        apolloObject = {
          mutation: REGISTER_USER,
          variables:{
            name: tempCopy.username,
            email: tempCopy.email,
            password: Math.random().toString(36).slice(-8),
            role: tempCopy.role,
            golfvereine: tempCopy.golfvereine
          }
        };
      }
      //createRecords(createJobs).then(()=>that.success());
      that.$apollo.mutate(apolloObject)
          .then(function(newRecordResponse){
            //set reference id to createJobs
            const createJobId = helpers.findValInObj(newRecordResponse,'id')[0];
            createJobs.forEach(x=>x.id=createJobId);

            that.$nextTick(function () {
                createRecords(createJobs, that).then((createdRecords) => {
                    //console.log("need to update verein: "+createJobId + " with user=",createdRecords)
                    if (createdRecords.length > 0) {
                        const cJob = {
                            list: that.routeModel.listName,
                            mutationKey: that.routeModel.mutationKey,
                            typeKey: that.routeModel.typeKey,
                            value: {
                                user: helpers.findValInObj(createdRecords, "id")[0]
                            },
                            id: createJobId
                    };
                        updateRecords([cJob], that).then(that.success, (er) => {
                            failed(er, {function: "updateRecord", job: cJob})
                        });
                }else{
                  that.success();
                }

              },(er)=>{failed(er, {function:"createRecords",job:createJobs})});
            })
          },(er)=>{failed(er, {function:"registerUserApollo from saver item helper",job:apolloObject})});
    }

}
