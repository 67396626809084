<template>
  <v-dialog v-model="campaignFormDialog">
    <v-card v-for="(form,i) in campaignForms" :key="'campaignInputForm_'+i">
      <v-card-title class="headline">Daten {{ dataExists ? 'bearbeiten' : 'eingeben' }}
        ({{ editedSourceRecordNames[i] }})
      </v-card-title>
      <v-card-text>
        <EditForm v-if="!loading" v-model="value[i]" :model="form" :scope="'campaignForm'+i"
                  @validation="formValidation"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="$emit('cancel')">Abbrechen</v-btn>
        <v-btn :disabled="formValid['campaignForm'+i] === false" color="blue darken-1" text
               @click="saveCampaignform(i)">Speichern
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>


import gql from "graphql-tag";
import gqlCreator from "@/helpers/gqlCreator";

export default {
  components: {
    EditForm: () => import('@/components/Forms/EditForm'),
  },
  name: "CampaignDataInput",
  props: {
    value: {
      required: true,
      type: [Object, Array]
    },
    campaignFormDialog: {
      type: Boolean,
      required: true
    },
    campaignForms: {
      type: Array,
      required: true
    },
    campaign: {
      type: Object,
      required: false
    },
  },
  methods: {
    formValidation(state, scope) {
      this.$set(this.formValid, scope, state);
      //this.formValid[scope]=state;
    },
    saveCampaignform(i) {
      let apolloMutation;
      if (this.dataExists) {
        //update
        apolloMutation = {
          mutation: gql`
          mutation updateCampaignsInput ($id: ID! $value: JSON!){
            updateCampaignsInput(input:{
            data:{
              value: $value
            }
            where: {id: $id}
            }){
              campaignsInput{id}
            }
          }
        `,
          variables: {
            id: this.existingData[0].id,
            value: Object.fromEntries(
                Object.keys(this.value[i])
                    .filter(name => ['record', 'form', 'campaign'].indexOf(name) === -1)
                    .map(x => [x, this.value[i][x]]))
          }
        };

      } else {
        //create
        apolloMutation = {
          mutation: gql`
          mutation createCampaignsInput ($relation: ID $name: String! $context: String! $campaign: ID! $value: JSON!){
            createCampaignsInput(input:{
            data:{
              ${this.$props.campaign.dataSource}: $relation
              context: $context
              campaign: $campaign
              name: $name
              value: $value
            }
            }){
              campaignsInput{id}
            }
          }
        `,
          variables: {
            name: this.$props.campaign.name,
            context: this.$props.campaign.dataSource,
            campaign: this.$props.campaign.id,
            relation: this.value[i].record.id,
            value: Object.fromEntries(
                Object.keys(this.value[i])
                    .filter(name => ['record', 'form', 'campaign'].indexOf(name) === -1)
                    .map(x => [x, this.value[i][x]]))
          }
        };
      }

      const that = this;
      this.$apollo.mutate(apolloMutation)
          .then(() => {
            that.$apollo.queries.existingData.refetch();
            that.$emit('cancel');//close dialog
          });
    },
    updateEditItem() {
      if (this.dataExists) {
        Object.entries(this.existingData[0].value).map(entry => {
          this.$set(this.$props.value[0], entry[0], entry[1]);
        });
      }
    }
  },
  computed: {
    dataExists() {
      return this.existingData.length > 0;
    },
    editedSourceRecordNames() {
      return Array.isArray(this.$props.value) ? this.$props.value.map(item => item.record.clubname || item.record.name || item.record.email) : []
    },
  },
  data() {
    return {
      formValid: {},
      loading: true,
      existingData: []
    }
  },
  watch: {
    existingData() {
      this.updateEditItem()
    },
    value() {
      this.updateEditItem()
    }
  },
  apollo: {
    existingData: {
      skip() {
        return this.campaign === undefined || !Array.isArray(this.value) || this.value.length < 1;
      },
      result() {
        this.loading = false;
      },
      query() {
        return gqlCreator.query({
          id: false,
          name: 'name',
          value: 'eingaben'
        }, 'campaignsInputs');
      },
      variables() {
        const campaign = this.$props.campaign;
        const record = this.$props.value[0].record;
        return {
          where: {
            "campaign": campaign.id,
            [campaign.dataSource]: record.id
          }
        }
      },
      update(data) {
        return data.campaignsInputs;
      },
    }
  }
}
</script>

