<template>
  <v-btn
      class="mx-1"
   dark
   rounded
   color="accent"
   :loading="loading"
   @click="startExport"
   >
   {{caption}}
   <v-icon dark>
     mdi-download
   </v-icon>
 </v-btn>
</template>

<script>
import gqlCreator from '../../helpers/gqlCreator';
import tableItemsHelper from "@/helpers/tableItemsHelper";

export default {
  props: {
    "filter": [Array, Object],
    "model": [Array, Object],
    "listName": String,
    "caption": String,
    "queryFields": Array,
    "sort": String,
    "exportName": String,
    "campaignId": String,
    "doneText": String,
    "unDoneText": String
  },
  name: 'CSVexport',
  data() {
    return {
      records: [],
      itemsConnection: {},
      items: {},
      skipDownload: true,
      skipCount: true,
      loading: false
    };
  },
  computed:{
    totalCount() {
      //return 0;
      return this.itemsConnection.aggregate ? this.itemsConnection.aggregate.count : 0;
    },
    start() {
      return this.records.length;
    },
    qFields() {
      //let model = this.model;
      return gqlCreator.modelToQF(this.$props.model);
    }
  },
  methods:{
    startExport(){
      this.skipCount = false;
      this.loading = true;
    },
    download(){
      if(this.loading){
        //prepare download
        //this.rendering = true;
        let csvString = '';
        let headerCols = [];
        const records = tableItemsHelper(this.model, this.records, true);
        records.forEach((r) => {
          // console.log(r)
          for (let col in r) {
            if (headerCols.indexOf('"' + col + '"') === -1) {
              headerCols.push('"' + col + '"');
            }
          }
        });
        for (let i = 0; i < records.length; i++) {
          let row = [];
          //headers
          if (i === 0) {

            if (this.$props.campaignId !== null && this.$props.campaignId !== undefined) {
              row.push('"Kampagnen-Aktion"');
            }

            csvString += headerCols.join(";") + "\n";
            // console.log(csvString)
            row = [];
          }

          //contents

          /*
          *  let row = {...x};
            if(this.campaignId !== null){
              row = {
                done: "false",
                ...row
              }
            }
          * */

          if (this.$props.campaignId !== null && this.$props.campaignId !== undefined) {
            //row.push('"'+(this.records[i].campaignsDone.indexOf(this.$props.campaignId) === -1)+'"');
            const thisUnDoneCampaigns = this.records[i].campaignsUndone.map(c => c.id);
            row.push('"' + (thisUnDoneCampaigns.indexOf(this.$props.campaignId) === -1 ? this.$props.doneText : this.$props.unDoneText) + '"');
            //row.push('"'+(JSON.stringify(this.records[i].campaignsDone))+'"');
          }

          for (let col in records[i]) {
            let v = records[i][col] === null ? '' : String(records[i][col]);
            row[headerCols.indexOf('"' + col + '"')] = '"' + v + '"';
          }
          csvString += row.join(";") + "\n";
        }
        let pom = document.createElement('a');
        //pom.setAttribute('href', 'data:text/csv;charset=ISO-8859-1,' + escape(csvString));
        pom.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvString));
        pom.setAttribute('download', this.exportName + '.csv');
        pom.click();
        this.loading = false;
        this.records = [];
        this.itemsConnection = {};
        this.items = {};
        this.skipCount = true;
        this.skipDownload = true;
        //add comments back to sublist after import
        if (this.$props.model.comments) {
          this.$props.model.comments.subList = true;
        }
      }
    }
  },
  apollo: {
      items: {
        query() {
          if (this.$props.model.comments) {
            this.$props.model.comments.subList = false;
          }
          return gqlCreator.query(this.$props.model, this.$props.listName, {
            qVars: 'sort: $sort, start: $start, limit: $limit, where: $where',
            qSpec: '$sort: String, $start: Int, $limit: Int, $where: JSON'
          }, this.qFields);
        },
        variables() {
          return {
            sort: this.sort,
            start: this.start,
            limit: 100,
            where: this.filter
          }
        },
        update (data) {
            return data[this.$props.listName];
        },
        skip () {
          return this.skipDownload;
        },
      },
      itemsConnection:{
        query(){
          return gqlCreator.query('count',this.$props.listName);
        },
        variables() {
          return {where:this.filter}
        },
        update (data) {
          return data[this.$props.listName+'Connection'];
        },
        skip () {
          return this.skipCount;
        },
      }
  },
  watch:{
    totalCount(){
      //console.log("count changed: "+ this.totalCount);
      if(this.totalCount !== 0){
          this.skipDownload = false;
      }
    },
    items(){
      if(Array.isArray(this.items)){
        this.items.map((x) => this.records.push(x));
      }
      if(this.records.length === this.totalCount){
        this.$nextTick(() => {
          this.download();
        });
      }
    }
  }
}
</script>
