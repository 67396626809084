export default (model,headers,subList) => {
  for (let field in model){
    const modelFields = Array.isArray(model[field]) ? model[field] : [model[field]];
    modelFields.map((modelField,index) => {
      if(modelField.rules === 'onlyAdmin' && localStorage.getItem("role") !== 'AppAdmin'){
        return;
      }
      if(modelField.visible !== false){
        const headValue = modelField.content !== undefined ? field + '.' + modelField.short : field;
        if(typeof modelField === 'string' || typeof modelField === 'object' && modelField.type !== 'list' && modelField.type !== 'media'){
          headers.push({
            text: modelField.label || (typeof modelField === "string"?modelField:field),
            value: headValue,
            key: index+field,
            editable: modelField.editable || true,
            type: modelField.type || 'text',
            display: modelField.display || modelField.type || 'text'
          });
        }else if(modelField.type === 'media' && subList === true){
          for(let subfield in modelField.model){
            if(typeof modelField.model[subfield] === 'string' || typeof modelField.model[subfield] === 'object'){
              headers.push({
                text: modelField.model[subfield].label || typeof modelField.model[subfield] === "string"?modelField.model[subfield]:subfield,
                value: subfield,
                key: index,
                editable: modelField.model[subfield].editable || true
              });
            }
          }
        }
      }
    });
  }
  return headers;
}
