<template>
  <v-btn :disabled="loading || disabled" :loading="computedLoading" class="ma-2 white--text" color="accent" @click="save">
    {{ text || 'Speichern' }}
    <v-icon right dark>
      mdi-cloud-upload
    </v-icon>
  </v-btn>
</template>
<script>
import axios from "axios";
import saveItemHelper from "@/helpers/saveItemHelper";
import createRecords from '@/helpers/createRecords';
import updateRecords from "@/helpers/updateRecords";

export default {

  props: {
    "editedItem": [Object, Array],
    "listModel": [Object, Array],
    "parent": Object,
    "disabled": Boolean,
    "editedIndex": Number,
    "routeModel": Object,
    "editedItemID": String,
    "originalItem": [Object, Array],
    "update": [Object, Array],
    "text": String,
    "create": [Object, Array],
    "isLoading": Boolean,
    "beforeSave": Function
  },
  name: 'SaveButton',
  data() {
    return {
      backend: process.env.VUE_APP_STRAPI_API_URL,
      loading: false,
      userId: null,
      competitionToProcess: null
    }
  },
  created() {
    this.userId = this.parseJwt(localStorage.getItem("token")).id;
  },
  computed: {
    computedLoading() {
      return this.$props.isLoading || this.loading;
    }
  },
  methods: {
    success() {
      //console.log("save-btn-success");
      this.$emit("success");
      this.loading = false;
    },
    failed(er) {
      console.log("failed in save btn");
      this.$emit("failed", er);
    },
    parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
    processCompetition(competitionName) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.backend}/competitions?_where[0][name]=${competitionName}`,
        headers: {
          Authorization: localStorage.getItem("token") || "",
        },
      };

      axios
        .request(config)
        .then((response) => {
          this.competitionToProcess = response.data;
          this.putRefereeApplicant(response.data[0].id)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    putRefereeApplicant(competitionId) {
      axios
          .get(`${this.backend}/competitions/${competitionId}`, {
            headers: {
              Authorization: localStorage.getItem("token") || "",
            },
          })
          .then((response) => {
            let ref_app_all =  response.data.referee_applicants;
            ref_app_all.push(this.userId)

            axios
                  .put(
                    `${this.backend}/competitions/${competitionId}`,
                    {
                      "referee_applicants": ref_app_all
                    },
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem("token") || "",
                      },
                    }
                  )
                  .then(function () {
                  })
                  .catch(function (error) {
                    console.log(error);
            });
      })
    },
    // putHotelwunsch(competitionId) {
    //
    //   axios
    //     .post(
    //       `${this.backend}/hotelwunsches`,
    //       {
    //         "competition": competitionId,
    //         "users_permissions_user": this.userId
    //       },
    //       {
    //         headers: {
    //           "Content-Type": "application/json",
    //           Authorization: localStorage.getItem("token") || "",
    //         },
    //       }
    //     )
    //     .then(function () {
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },

    async save() {
      // catching campain inputs
      if (this.$props.create && this.$props.create[0]) {
        if (localStorage.getItem("role") === "referee" && this.$props.create[0].list === "campaignsInput") {

          // console.log(this.$props.create[0])

          if (this.$props.create[0].value && this.$props.create[0].value.value && this.$props.create[0].value.value.Wettspiele)
            for (const wettspiel of this.$props.create[0].value.value.Wettspiele) {
              this.processCompetition(wettspiel.name)
            }
        }
      }

      if (typeof this.$props.beforeSave === 'function') {
        await this.$props.beforeSave();
      }

      let updatesDone = this.$props.update === undefined || this.$props.update.length === 0;
      let createsDone = this.$props.create === undefined || this.$props.create.length === 0;

      if (this.$props.update !== undefined) {
        this.loading = true;
        updateRecords(this.$props.update, this)
          .then(() => {
            updatesDone = true;
            if (createsDone) {
              this.$emit("success");
              this.loading = false;
            }
          });
      } else {
        updatesDone = true;
      }
      if (this.$props.create !== undefined) {
        this.loading = true;
        createRecords(this.$props.create, this)
          .then(() => {
            createsDone = true;
            if (updatesDone) {
              this.loading = false;
              this.$emit("success");
            }
          });
      }
      if (this.$props.create === undefined && this.$props.update === undefined) {
        //complex save logic (users and clubs...)
        saveItemHelper(this);
      }

    }
  }
}
</script>
