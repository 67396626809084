<template>
    <v-btn class="mx-1" dark rounded color="accent" :loading="loading" @click="startExport">
        {{ caption }}
        <v-icon dark> mdi-download </v-icon>
    </v-btn>
</template>

<script>
import axios from "axios";

export default {
    name: "CSVRefereeExport",
    props: {
        model: [Array, Object],
        filter: [Array, Object],
        caption: String,
    },
    data() {
        return {
            records: [],
            itemsConnection: {},
            items: {},
            skipDownload: true,
            skipCount: true,
            loading: false,
            backend: process.env.VUE_APP_STRAPI_API_URL,
        };
    },
    methods: {
        startExport() {
            let season = ""
            if (this.filter && this.filter._where && this.filter._where._or && this.filter._where._or[0]) {
                const filter = this.filter._where._or[0]
                if (filter.season_contains) {
                    season = filter.season_contains
                }
            }

            try {
                this.loading = true;

                let config = {
                    method: "get",
                    maxBodyLength: Infinity,
                    // strapi 3 filtering
                    // https://docs-v3.strapi.io/developer-docs/latest/developer-resources/content-api/content-api.html#filters
                    // we want to filter competitions / wettspiele that have already taken place
                    // url: `${this.backend}/abrechnungens?_where[0][competition.competitionEndDate_lt]=2023-04-13`,

                    url: season ? `${this.backend}/competitions?season_eq=${season}` : `${this.backend}/competitions`,
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                };

                axios
                    .request(config)
                    .then((response) => {
                        this.genCSV(response.data, season)
                    })
                    .catch((error) => {
                        console.log(error);
                    });


            } catch (error) {
                console.debug(error)
            }
        },
        genCSV(competitions, season) {
            try {

                let refereeApplicants = {}
                let _competition = new Set()

                for (const competition of competitions) {
                  // console.log(competition)

                    _competition.add(
                        {
                          competitionName:competition.name,
                          competitionStartDate:competition.competitionStartDate,
                          competitionEndDate:competition.competitionEndDate,
                          competitionOrganizer:competition.organizer?competition.organizer.clubname:'',
                          deploymentStartDate:competition.deploymentStartDate,
                          deploymentEndDate:competition.deploymentEndDate,

                        }
                    )

                    for (const referee_applicant of competition.referee_applicants) {


                        if (Array.isArray(refereeApplicants[referee_applicant.username])) {
                            refereeApplicants[referee_applicant.username].push(competition.name)
                        } else {
                            refereeApplicants[referee_applicant.username] = []
                            refereeApplicants[referee_applicant.username].push(competition.name)
                        }
                    }

                }

                let twoD = []
                let header = []
                header.push("Wettspiel")
                header.push("Turnier Startdatum")
                header.push("Turnier Enddatum")
                header.push("Austräger")
                header.push("Einsatzzeitraum Start")
                header.push("Einsatzzeitraum Ende")
                Object.keys(refereeApplicants).forEach((key) => {
                    header.push(key)
                });

                twoD.push(header)

                for (const entry of _competition) {

                    let temp = []
                    temp.push(entry.competitionName)
                    temp.push(entry.competitionStartDate)
                    temp.push(entry.competitionEndDate)
                    temp.push(entry.competitionOrganizer)
                    temp.push(entry.deploymentStartDate)
                    temp.push(entry.deploymentEndDate)
                    for (let index = 0; index < Object.keys(refereeApplicants).length; index++) {
                        let competitions = Object.values(refereeApplicants)[index]
                        if (competitions.includes(entry.competitionName)) {
                            temp.push("*")
                        } else {
                            temp.push("")
                        }
                    }
                    twoD.push(temp)
                }

                const CSV = twoD.map(row => row.map(attribute => `"${attribute}"`).join(",")).join('\n')
                const element = document.createElement("a");
                const file = new Blob([CSV], { type: "text/csv" });
                element.href = URL.createObjectURL(file);
                element.download = this.filename(season);
                document.body.appendChild(element);
                element.click();
                this.loading = false;

            }

            catch (error) {
                console.debug(error)
            }
        },
        filename(season) {
            const date = new Date();
            const YYYYMMDD = date.toISOString().substring(0, 10);
            let filename = ""
            season ? filename = `Referee-Export-[Season-${season}]-${YYYYMMDD}.csv` : filename = `Referee-Export-${YYYYMMDD}.csv`
            return filename
        }
    },
};
</script>

<style>

</style>
