<template>
  <tr>
    <th v-for="(head,index) in cleanHeaders" :key="head.key+'head'">
      <template v-if="searchFields.includes(headers[index].value)">
        <template
            v-if="searchFieldModels[head.value]['type'] === 'date' || searchFieldModels[head.value]['type'] === 'datetime'">
          <v-menu v-model="dMenu[head.value]"
                  :close-on-content-click="false"
                  :position-x="dMenuX"
                  :position-y="dMenuY" transition="scale-transition" min-width="290px">
            <v-date-picker @input="dMenu[head.value]=false" v-model="ILSearch[head.value]" locale="de-de"
                           :no-title="true" :first-day-of-week="1"></v-date-picker>
          </v-menu>
          <v-text-field v-model="ILSearch[head.value]"
                        label="" dense hide-details clearable outlined prepend-inner-icon="mdi-calendar"
                        :append-icon="ILSearch[head.value] !== '' &&  ILSearch[head.value]  !== undefined &&  ILSearch[head.value]  !== null ?( ilIcon[head.value] !== undefined ? ilIcon[head.value].icon : 'mdi-equal'):''"
                        @click:append="changeOperator(head.value)"
                        @click:prepend-inner="ev=>showMenu(head.value,ev)"

                     ></v-text-field>
        </template>


        <v-switch
            v-else-if="searchFieldModels[head.value]['type'] === 'checkbox'"
            v-model="ILSearch[head.value]"
            @change="searchSwitch(head.value)"
            :append-icon="boolSearchClicks[head.value]>0?'mdi-filter':''"
            label=""
        ></v-switch>
        <template v-else-if="searchFieldModels[head.value].display === 'threeState'">
          <v-icon
              @click="threeSwitch(head.value)"
          >
            {{threeStateIcons[head.value] === undefined ? 'mdi-magnify' : threeStateIcons[head.value]}}
          </v-icon>
        </template>

        <v-text-field
            v-else
            dense hide-details clearable outlined
            v-model="ILSearch[head.value]"
            prepend-inner-icon="mdi-magnify"
        >
        </v-text-field>
      </template>

    </th>
  </tr>
</template>
<script>
import threeStateIcon from "@/helpers/threeStateIcon";
import functions from "@/helpers/functions";

export default {
  name: 'InlineSearch',
  props: {
    headers:{
      type: Array,
      required: true
    },
    searchFields: {
      type: Array,
      required: true
    },
    model:{
      type: Object,
      required: true
    }
  },
  data () {
    return {
      ILSearch: {},
      changed: 0,
      boolSearchClicks: {},
      dMenu: {},
      dMenuX: 0,
      dMenuY: 0,
      ilOperators: {}
    }
  },
  computed: {
    ilIcon(){
      let retO = {...this.ilOperators}
      retO.changedTrigger = this.changed;
      return retO;
    },
    cleanHeaders(){
      return functions.cleanHeaders(this.headers);
      /*let newHeaders = {};
      for(let head in this.headers){
        let newKey = this.headers[head].value.replace(/\./g,'-');
        newHeaders[head] = {
          ...this.headers[head]
        }
        newHeaders[head].value = newKey;
      }
      return newHeaders;*/
    },
    searchFieldModels(){
      return functions.searchFieldModels(this.model,this.headers);
      /*let sfModels = {};
        for(let head in this.headers){
          let key = this.headers[head].value;
          if(this.model[key] !== undefined){
            sfModels[key] = this.model[key];
          }else{
            if(this.model[key.split(".")[0]] !== undefined){
              const aKey = key.split(".")[0];
              const fieldName = key.split(".")[1];
              if(Array.isArray(this.model[aKey])){
                this.model[aKey].map((x)=>{
                  if(x.content === fieldName){
                    sfModels[aKey+'-'+fieldName] = x;
                  }
                });
              }
            }
          }
        }
      return sfModels;*/
    },
    threeStateIcons(){
      let icons = {};
      icons.changedTrigger = this.changed;
      for(let sf in this.ILSearch){
        icons[sf] = this.boolSearchClicks[sf] !== undefined ? threeStateIcon(this.ILSearch[sf]) : 'mdi-magnify';
      }
      return icons;
    }
  },
  watch: {
    changed(){
      this.$emit("ilChange",this.ILSearch,this.ilOperators);
    },
    ILSearch(newSearch){
      //console.log(outSearch);
      this.$emit("ilChange",newSearch,this.ilOperators);
    }
  },
  methods: {
    showMenu(value,ev){
      this.dMenuX = ev.clientX;
      this.dMenuY = ev.clientY;
      this.$set(this.dMenu,value,true); //force change trigger

    },
    changeOperator(headKey){
      if(this.ilOperators[headKey] === undefined || this.ilOperators[headKey].state === 0){
        this.ilOperators[headKey] = {
          operator: '_gt',
          icon: 'mdi-greater-than',
          state: 1
        };
      }else if (this.ilOperators[headKey].state === 1){
        this.ilOperators[headKey] = {
          operator: '_lt',
          icon: 'mdi-less-than',
          state: 2
        };
      }
      else if (this.ilOperators[headKey].state === 2){
        this.ilOperators[headKey] = {
          operator: '',
          icon: 'mdi-equal',
          state: 0
        };
      }
      this.changed++;
    },
    threeSwitch(fieldName){
      if(this.boolSearchClicks[fieldName] === undefined){
        this.boolSearchClicks[fieldName] = 1;
        this.ILSearch[fieldName] = 1;
      }else{
        this.boolSearchClicks[fieldName]++;
      }
      if( this.boolSearchClicks[fieldName] === 2){
        this.ILSearch[fieldName] = 0
      }
      if( this.boolSearchClicks[fieldName] === 3){
        this.ILSearch[fieldName] = -1
      }
      if(this.boolSearchClicks[fieldName] > 3){
        delete this.boolSearchClicks[fieldName];
        this.ILSearch[fieldName] = '';
      }
      //this.threeStateIcons[fieldName] = threeStateIcon(this.ILSearch[fieldName]);
      this.$emit("ilChange",this.ILSearch);
      this.changed++;
    },
    searchSwitch(fieldName){
      if(this.boolSearchClicks[fieldName] === undefined){
        this.boolSearchClicks[fieldName] = 1;
      }else{
        this.boolSearchClicks[fieldName]++;
      }
      if(this.boolSearchClicks[fieldName] > 2){
        delete this.ILSearch[fieldName];
        this.boolSearchClicks[fieldName] = 0;
      }
    },
  }
}
</script>
