export default (model,userRole) => {
  let subLists = [];
  for (let field in model) {
    const modelFields = Array.isArray(model[field]) ? model[field] : [model[field]];
    let fieldsCollection = {};
    const roleSeesField = (model) => {
      return (model.roles !== undefined && model.roles.indexOf(userRole) !== -1);
    };

    const isReadOnly = (model) => {
      return (model.readOnly === true || model.readOnly !== undefined && model.readOnly.indexOf(userRole) !== -1);
    };

    modelFields.forEach((modelField) => {
      if (modelField.subList === true && roleSeesField(modelField)) {
        if (fieldsCollection[field] === undefined) {
          fieldsCollection[field] = subLists.length;
          subLists.push({
            name: field,
            listName: modelField.listName,
            label: modelField.label,
            model: modelField.model,
            key: modelField.key,
            type: modelField.type,
            editable: modelField.editable,
            actionEditDisabled: modelField.actionEditDisabled || isReadOnly(modelField),
            actionDeleteDisabled: modelField.actionDeleteDisabled || isReadOnly(modelField),
            actionUnlinkDisabled: modelField.actionUnlinkDisabled || isReadOnly(modelField),
            actionCreateDisabled: modelField.actionCreateDisabled || isReadOnly(modelField),
            newRcrdBtnTxt: modelField.newRcrdBtnTxt,
            assignRcrdBtn: modelField.assignRcrdBtn,
            assignRcrdBtnTxt: modelField.assignRcrdBtnTxt,
            assignRefereeRcrdBtn: modelField.assignRefereeRcrdBtn,
            assignRefereeRcrdBtnTxt: modelField.assignRefereeRcrdBtnTxt,
            assignRefereeRcrdBtn_allowedRoles: modelField.assignRefereeRcrdBtn_allowedRoles,
            mutationKey: modelField.mutationKey,
            createMutation: modelField.createMutation,
            typeKey: modelField.typeKey,
          });
        }else{
          subLists[fieldsCollection[field]].model = {
            ...subLists[fieldsCollection[field]].model,
            ...modelField.model
          };
        }

      }
    });
  }

  return subLists;
}
