/*
Filter Display component used in Main List and Filter Editor.
should get a delete filter button that should execute the $props.deleteAction
*/
<style scoped>
  .filterDisplay >>> .or-btn{
    position:absolute;
    z-index:99;
    left:10px;
    bottom:-13px;

  }
  .filterDisplay >>> .and-or-btn{
    font-weight:bold;
  }
  .filterDisplay >>> .v-chip--disabled{
    opacity:1;
    font-weight:normal;
  }
</style>
<template>
  <div class="filterDisplay">
    <v-card
      outlined
      v-for="(orA,i) in filterC"
      :key="'orA'+i"
    >
    <v-container>
      <v-row>
        <v-col cols="12" :md="deleteAction!==undefined?11:12">
          <v-chip-group
            column
          >
            <span
              v-for="(andA,a) in orA"
              :key="'andA'+a">
              <v-chip
                v-for="(chip,c) in andA"
                :key="'chip'+c"
                :color="c%2===0?'primary':'secondary'"
                small
                @click="chipClick()"
              >
               {{chip}}
              </v-chip>
              <v-chip
              class="and-or-btn"
                :disabled="a !== (orA.length-1) || !(nextFilterOperant === null || nextFilterOperant === 'and' && filterIndex === i)"
                small
                v-if="andAction !== undefined || a < (orA.length-1)"
                @click="andAction('and',i)">
                <v-icon small v-if="a === (orA.length-1)">
                  mdi-plus
                </v-icon>
                und
              </v-chip>
            </span>
          </v-chip-group>
          <v-chip
            :disabled="i !== (filterC.length-1) || !(nextFilterOperant === null || nextFilterOperant === 'or'  && filterIndex === i)"
            class="or-btn and-or-btn"
            small
            v-if="orAction !== undefined || i < (filterC.length-1)"
            @click="orAction('or',i)">
            <v-icon v-if="i === (filterC.length-1)" small>
              mdi-plus
            </v-icon>
            oder
          </v-chip  >
        </v-col>
        <v-col cols="12" md="1" v-if="deleteAction!==undefined">
          <v-btn color="primary"
          small
          icon
          @click="deleteAction(i)">
            <v-icon dark>
              mdi-delete
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    </v-card>
  </div>
</template>

<script>
import makeFieldDisplayName from "@/helpers/makeFieldDisplayName";

export default {
  props: [
    "filter",
    "andAction",//SHOULD BE CHANGED TO EVENTS If defined, the and button is clickable and executes the action: andAction('and',filterIndex)
    "orAction",//SHOULD BE CHANGED TO EVENTS If defined, the or button is clickable and executes the action: orAction('or',filterIndex)
    "deleteAction",//SHOULD BE CHANGED TO EVENTS not ready yet
    "nextFilterOperant",//SHOULD BE CHANGED TO EVENTS used to display or hide buttons
    "filterIndex",//used to display or hide buttons
    "listModel"
  ],
  name: 'FilterDisplay',
  data () {
    return {
    };
  },
  methods:{
    chipClick () {
      // console.log("chip clicked emiting event");
      this.$emit('chipClick');
    }
  },
  computed:{
    filterC(){
      let operators = {
        eq: '=',
        ne: 'ist nicht',
        contains: 'enthält',
        ncontains: 'enthält nicht',
        lt: '<',
        gt: '>'
      };
      let fc = [];
      const that = this;
      this.filter.map(function(oF){
        let ands = [];

        oF.map(function(aF){
          for(let k in aF) {
            const fieldName = k.substr(0, k.lastIndexOf('_'));
            let fieldDisplay = makeFieldDisplayName(fieldName, that.$props.listModel[fieldName]);
            let op = k.substr(k.lastIndexOf('_') + 1, k.length - 1);
            ands.push(['<' + fieldDisplay + '>', operators[op], '>' + aF[k] + '<']);
          }
        });
        fc.push(ands);
      });
      return fc;
    }
  }
}
</script>
