export default (dataRecord, model, parent, originalItem) => {
  let newData = {};
  //first check field relations
  for (let field in model.model) {
    if (model.model[field].update !== undefined) {
      model.model[field].update(dataRecord);
    }
  }
  if (model.key !== undefined && parent !== undefined) {
    newData[model.key] = parent.id;
  }

  for (let key in dataRecord) {
    if (
      key !== "id" &&
      key !== "__typename" &&
      dataRecord[key] !== undefined &&
      key !== model.key
    ) {
      const fieldModel = Array.isArray(model.model[key])
        ? model.model[key][0]
        : model.model[key];
      let v = dataRecord[key];
      if (fieldModel !== undefined && v !== null) {
        switch (fieldModel.type) {
          case "number":
          case "money":
            v = Number(dataRecord[key]);
            break;
          case "checkbox":
            v = Boolean(dataRecord[key]);
            break;
          case "date":
            if (dataRecord[key] === "") {
              v = null;
            } else {
              v = new Date(dataRecord[key]);
              v = v.toISOString().split("T")[0];
            }
            break;
          case "datetime":
            /*v = new Date(dataRecord[key]);
                        v.setHours(v.getHours() + 1);*/
            //v = v.toISOString();
            break;
          case "select":
            if (typeof v === "object" && v.id !== undefined) {
              v = v.id;
            }
            if (Array.isArray(v)) {
              v = v.map((x) => x.id || x);
            }
            break;
          case "media":
            // newData.alternativeText = dataRecord.alternativeText ? "internal" : null
            if (parent) {
              newData.files = v;
              newData.refId = parent.id;
              newData.ref = parent.clubname ? parent.clubname : undefined;
            }
            if (fieldModel.model.caption) {
              newData.caption = dataRecord.caption;
            }
            if (fieldModel.model.alternativeText) {
              newData.alternativeText = dataRecord.alternativeText;
            }
          // if (!dataRecord.alternativeText) newData.alternativeText = null
        }
      }
      if (fieldModel && fieldModel.reference && fieldModel.value !== "id") {
        //console.log("need to store in users",originalItem[key]);
        const oItem = originalItem !== undefined ? originalItem[key] : {};
        if (oItem !== null && oItem[fieldModel.value] !== v) {
          newData[key] = {
            ...oItem,
            ...{ [fieldModel.value]: v },
          };
        } else if (oItem === null) {
          newData[key] = {
            [fieldModel.value]: v,
          };
        }
      } else if (
        (fieldModel && fieldModel.type !== "media") ||
        parent !== undefined
      ) {
        newData[key] = v;
      }
      // delete if null for non nullable objects -> not so nice solution, as the caption has no model. need to restructure model logic
      if (fieldModel && fieldModel.reference) {
        if (
          key !== "caption" &&
          ((v === null && fieldModel.reference !== true) ||
            fieldModel === undefined ||
            fieldModel.save === false)
        ) {
          delete newData[key];
        }
      }
    }
  }
  return newData;
};
