export default {
  settingsChecked: [
    v => v || 'Bitte geben Sie an, dass Sie die Einstellungen überprüft haben.'
  ],
  privacy: [
    v => v || 'Bitte geben Sie an, dass Sie die mit unseren Datenschutzbestimmungen einverstanden sind.'
  ],
  notEmptyRules: [
    v => !!v || 'Dies ist ein Pflichtfeld.',
    v => !Array.isArray(v) || v.length !== 0 || 'Bitte wählen Sie mindestens eine Option.'
  ],
  trueOrFalse: [
    v => v === true || v === false || 'Dies ist ein Pflichtfeld.'
  ],
  noSpecialChars: [
    v => /^[A-Za-z0-9]{3,32}$/.test(v) || 'Dieses Feld muss zwischen 3 und 32 Zeichen haben und es können keine Sonderzeichen eingeben werden.'
  ],
  emailRules: [
    v => !!v || 'Bitte geben Sie eine E-Mail-Adresse ein.',
    v => /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || 'Die eingegebene E-Mail-Adresse scheint nicht gültig zu sein.',
  ],
  numberRules: [
    v => !!v || 'Bitte geben Sie eine Nummer ein.',
    v => /^[0-9]+$/.test(v) || 'Bitte nur Zahlen eingeben.',
  ],
  nullOrInt: [
    v => (v === undefined || v === null) || v[0] !== '0' || 'Die Zahl darf keine führende Null enthalten.', // !== undefined since it throwed an error when unloading the component.. v !== null || v !== undefined || v !== '' ||
    v => (v === undefined || v === null) || /^[0-9]+$|^$/.test(v) || 'Bitte geben Sie eine Zahl ein, oder lassen Sie das Feld leer.',
  ],
  euroRules: [
    v => /[0-9]?[0-9]?(\.[0-9][0-9]?)/.test(v) || 'Bitte geben Sie eine Zahl mit zwei Kommastellen ein. Das Trennzeichen muss ein Punkt sein.',
  ],
  passRules: [
    v => !!v || 'Bitte geben Sie ein Passwort ein.',
    v => /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/.test(v) || 'Mindestens 8 Zeichen, mindestens einen Großbuchstaben und einen Kleinbuchstaben.',
  ],
  urlOrEmpty: [
    v => v === null || v === undefined || v === '' || /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(v) || 'Bitte überprüfen sie Ihre Eingabe.'
  ],
  yearOrEmpty: [
    v => /^[0-9]+$/.test(String(v)) && String(v).length === 4 || String(v) === '' || v === null || v === undefined || 'Bitte eine Jahreszahl (YYYY) eingeben.'
  ],
  yearNotEmpty: [
    v => /^[0-9]+$/.test(String(v)) && String(v).length === 4 || 'Bitte eine Jahreszahl (YYYY) eingeben.'
  ]
}
