<template>
  <v-card outlined>
    <v-container>
      <v-row v-if="item.__typename === 'Email'">
        <v-col cols="12">
          <div v-html="item.content ? item.content : 'no content'"></div>
          <JsonViewer v-if="item.data" :value="item.data" />
          <br />
          <template v-if="role === 'AppAdmin'">
            <v-btn color="accent" @click="dialogSendAgain = true">Erneut senden</v-btn>
            <v-dialog v-model="dialogSendAgain" max-width="600px">
              <v-card>
                <v-card-title class="headline">Wollen Sie diese E-Mail wirklich erneut senden?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialogSendAgain = false">Abbrechen</v-btn>
                  <v-btn :loading="loading" color="accent" @click="sendAgain">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-col>
      </v-row>
      <v-row v-if="item.__typename === 'UploadFile' || item.__typename === 'Invoice'">
        <v-col cols="12">
          <!--          <v-card height="1697" min-width="1200" style="border:1px solid black;transform: scale(0.9);
                    transform-origin: top left;" width="1200" v-html="item.caption"></v-card>-->
          <iframe ref="iframe"
            style="outline:1px solid black;border:0 solid white;width: 210mm;min-height: 297mm;transform-origin: top left;">
            {{ item.caption }}
          </iframe>
        </v-col>
      </v-row>

      <v-row v-if="item.__typename === 'Competition'">
        <v-col cols="12">
          Wettspiel: {{ item.name }} <br>
          Season: {{ item.season }} <br>
          <section class="comment">Kommentar: {{ item.comment || "N/A" }}</section><br>

          Austräger: <section v-if="item.organizer">{{ item.organizer.clubname }}</section>
          <section v-else>Austräger noch nicht festgelegt</section> <br>
          <section v-if="item.organizer">
            Adresse: {{ item.organizer.strasse || "N/A" }} {{ item.organizer.hausnummer || "N/A" }}, {{ item.organizer.plz
              || "N/A" }}, {{
    item.organizer.ort || "N/A" }}<br>
            Website: {{ item.organizer.website || "N/A" }}<br><br>
          </section>

          Startdatum Turnier: {{ moment(item.competitionStartDate, 'YYYY-MM-DD').format("DD.MM.YYYY") || "N/A" }} <br>
          Enddatum Turnier: {{ moment(item.competitionEndDate, 'YYYY-MM-DD').format("DD.MM.YYYY") || "N/A" }} <br><br>

          Startdatum Einsatzzeitraum: {{ moment(item.deploymentStartDate, 'YYYY-MM-DD').format("DD.MM.YYYY") || "N/A" }}
          <br>
          Enddatum Einsatzzeitraum: {{ moment(item.deploymentEndDate, 'YYYY-MM-DD').format("DD.MM.YYYY") || "N/A" }}
          <br><br>

          Referees:
          <ul>
            <li v-for="item in item.referees" v-bind:key="item.id">{{ item.username || "N/A" }}</li>
          </ul><br>

          Meldeschluss: {{ moment(item.closingDate, 'YYYY-MM-DD').format("DD.MM.YYYY") || "N/A" }} <br><br>

          <section v-if="hotelDataset">
            <hr><br>
            Gebuchtes Hotel: {{ hotelDataset.name || "N/A" }} <br><br>
            Adresse: {{ hotelDataset.Strasse || "N/A" }} {{ hotelDataset.Hausnummer || "N/A" }}<br>
            Ort: {{ hotelDataset.PLZ || "N/A" }} {{ hotelDataset.Ort || "N/A" }} <br>
            Telefon: {{ hotelDataset.Telefonnummer || "N/A" }} <br>
            E-Mail: {{ hotelDataset.email || "N/A" }} <br><br>

            Beginn Frühstück: {{ hotelDataset.BeginnFruehstueck || "N/A" }} <br>
            Beginn Frühstück Sonntag: {{ hotelDataset.BeginnFruehstueckSonntag || "N/A" }} <br>
            Website: {{ hotelDataset.URL || "N/A" }} <br>
          </section>
        </v-col>
      </v-row>

      <v-row v-if="item && item.__typename === 'CompetitionApplications' && item.organizer">
        <template v-if="item.organizer">
          <v-col cols="12">
            <b>Adresse:</b>
            <v-banner elevation="2" outlined sticky style="white-space: pre-line">{{ address }}
            </v-banner>

          </v-col>
          <v-col cols="12">
            <b>Telefon/Fax:</b>
            <v-banner elevation="2" outlined sticky style="white-space: pre-line">Telefon: {{ phone }}
              Fax: {{ item.organizer.fax }}
            </v-banner>
            row
          </v-col>
          <v-col cols="12">
            <b>E-Mail-Adresse:</b>
            <v-banner elevation="2" outlined sticky style="white-space: pre-line">{{ item.organizer.user.email }}
            </v-banner>

          </v-col>
          <section v-if="item && item.organizer">
            <v-col v-if="item.organizer.website" cols="12">

              <v-btn
                :href="item.organizer.website.toLowerCase().substr(0, 4) === 'http' ? item.organizer.website : 'https://' + item.organizer.website"
                target="_blank" text>
                {{ item.organizer.website }}
              </v-btn>
            </v-col>
          </section>
        </template>
        <template v-else>
          <v-col>Austräger steht nich nicht fest</v-col>
        </template>
      </v-row>

      <v-row v-if="item.__typename === 'Golfvereine'">
        <v-col cols="12">
          <b>Adresse:</b>
          <v-banner elevation="2" outlined sticky style="white-space: pre-line">
            {{ address }}
          </v-banner>

        </v-col>
        <v-col cols="12">
          <b>Telefon/Fax:</b>
          <v-banner elevation="2" outlined sticky style="white-space: pre-line">
            Telefon: {{ phone }}<br />
            Fax: {{ item.fax }}
          </v-banner>

        </v-col>
        <v-col cols="12">
          <b>E-Mail-Adresse:</b>
          <v-banner elevation="2" outlined sticky style="white-space: pre-line">
            {{ item.user }}
          </v-banner>

        </v-col>
        <v-col v-if="item.website" cols="12">
          <b>Webseite:</b>
          <v-banner elevation="2" outlined sticky style="white-space: pre-line">
            <v-btn :href="item.website.toLowerCase().substr(0, 4) === 'http' ? item.website : 'https://' + item.website"
              target="_blank" text>
              {{ item.website }}
            </v-btn>

          </v-banner>

        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import JsonViewer from "vue-json-viewer";
import createRecords from "@/helpers/createRecords";
import axios from "axios";

export default {
  props: {
    item: Object
  },
  components: { JsonViewer },
  name: 'DataView',
  data() {
    return {
      dialogSendAgain: false,
      loading: false,
      backend: process.env.VUE_APP_STRAPI_API_URL,
      hotelDataset: false,
    };
  },
  created() {
    // console.log(this.item)
  },
  mounted() {
    this.$nextTick(() => {
      this.$nextTick(() => {
        this.updateIframe();
      })
    });

    if (this.item.hotel && this.item.hotel.id) {
      this.fetchHotelDataset(this.item.hotel.id)
    }
  },
  watch: {
    item() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.updateIframe();
        })
      });
      if (this.item.hotel && this.item.hotel.id) {
        this.fetchHotelDataset(this.item.hotel.id)
      } else {
        this.hotelDataset = false;
      }
    },
  },
  methods: {
    sendAgain() {
      this.loading = true;
      createRecords([{
        typeKey: 'Email',
        mutationKey: 'email',
        list: 'email',
        value: {
          sentCheck: 'sentAgain' + this.item.sentCheck + new Date(),
          date: new Date(),
          data: {
            formData: {},
            recipient: this.item.recipient,
            content: this.item.content,
            subject: this.item.subject,
            campaign: this.item.campaign ? this.item.campaign.id : null,
            golfvereine: this.item.golfvereine.id,
            moduleName: this.item.moduleName
          }
        }
      }], this)
        .then(() => {
          this.loading = false;
          this.dialogSendAgain = false;
          //this.$emit("success");
        });
    },
    updateIframe() {
      if (this.$refs.iframe) {
        const doc = this.$refs.iframe.contentWindow.document;
        doc.open();
        doc.write(this.item.html || this.item.caption);
        doc.close();
      }
    },
    fetchHotelDataset(hotelId) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        // strapi 3 filtering
        // https://docs-v3.strapi.io/developer-docs/latest/developer-resources/content-api/content-api.html#filters
        url: `${this.backend}/hotels?id_eq=${hotelId}`,
        headers: {
          Authorization: localStorage.getItem("token") || "",
        },
      };

      axios
        .request(config)
        .then((response) => {
          this.hotelDataset = response.data[0];
          this.$nextTick(() => {
            this.$nextTick(() => {
              this.updateIframe();
            })
          });
        })
        .catch((error) => {
          console.log(error);
        });

    }
  },
  computed: {
    role() {
      return localStorage.getItem('role');
    },
    phone() {
      if (this.item && this.item.organizer && this.item.__typename === 'CompetitionApplications') {
        return this.item.organizer.telefon || '-'
      }
      return this.item.telefon;
    },
    address() {
      if (this.item && this.item.organizer) {
        const item = this.item.__typename === 'CompetitionApplications' ? this.item.organizer : this.item;
        return `${item.clubname}
          ${item.strasse} ${item.hausnummer}
          ${item.plz} ${item.ort}`;
      } else {
        const item = this.item;
        return `${item.clubname}
        ${item.strasse} ${item.hausnummer}
        ${item.plz} ${item.ort}`;
      }
    }
  }
}
</script>

<style>
.comment {
  color: red;
  font-weight: bold;
}
</style>
