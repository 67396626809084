export default (fieldName, model) => {
  let fieldDisplay = fieldName;
  if (model !== undefined) {
    fieldDisplay = model.label || fieldName;
    if (model.reference === true) {
      if (model.model && model.model[model.long]) {
        fieldDisplay =
          fieldDisplay +
          "." +
          (model.model[model.long].label ||
            model.model[model.long] ||
            model.long);
      }
    }
  }
  return fieldDisplay;
};
