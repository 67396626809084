<template>
  <div>
    <v-col v-if="error" class="text-xs-center">
      <v-alert type="error">{{ error }}</v-alert>
    </v-col>
    <template v-if="error === null">
      <!-- Einstellungen tabs -->
      <TabLists v-if="isLoggedIn && routeModel && routeModel.anzeigeTyp === 'tabs'" :sublists="sublists"
        :listTitle="routeModel.listTitle" />
      <!-- Einstellungen tabs ENDE -->

      <v-card v-else-if="isLoggedIn" class="mx-auto">

        <!-- SUCHE UND FILTER -->
        <v-container>
          <v-row align="end">
<!--            <v-col cols="12" md="2">-->
<!--              <v-text-field v-model="search" append-icon="mdi-magnify" label="Suchbegriff eingeben..." single-line-->
<!--                hide-details></v-text-field>-->
<!--            </v-col>-->
<!--            <v-col v-if="!isInCampaignWorkMode" cols="12" md="2">-->
<!--              <FilterEditor :key="routeModel.listTitle + 'fe'" ref="filterEditor" v-model="filters[routeName]"-->
<!--                :editFilter="editFilter[routeName]" :model="routeModel" />-->
<!--            </v-col>-->
            <v-col cols="12" md="8">
              <FilterDisplay :editFilter="editFilter[routeName]"
                :filter="isInCampaignWorkMode ? campaignFilter : filters[routeName]" :list-model="listModel"
                :key="routeModel.listTitle + 'fd'" @chipClick="filterDisplayClick()" />
            </v-col>
          </v-row>
<!--         Kampagne erstellen-->
<!--          <v-row v-if="routeModel.actions && routeModel.actions.roles.includes(userRole)&& !isInCampaignWorkMode" align="center">-->
<!--            <v-col cols="12" md="2" v-if="selectedItems.length !== 0">-->
<!--              Aktion für {{ selectedItems.length === 1 ? "einen Datensatz" : selectedItems.length + " Datensätze" }}-->
<!--              erstellen:-->
<!--            </v-col>-->
<!--            <v-col cols="12" md="2" v-else-if="selectedItems.length === 0">-->
<!--              Aktion für Datenbankfilter erstellen (aktuell-->
<!--              {{ totalCount === 1 ? "ein Datensatz" : totalCount + " Datensätze" }}):-->
<!--            </v-col>-->
<!--            <v-col cols="12" md="2">-->
<!--              <SelectList v-model="selectedAction" :default-item="{ name: 'Neue Aktion konfigurieren', id: 'none' }"-->
<!--                :filter="actionsFilter" :model="actionsModel" listName="campaigns" />-->
<!--            </v-col>-->
<!--            <v-col cols="12" md="8">-->
<!--              <v-btn class="mx-2" fab dark small color="accent" @click="openCampaignEditor" v-if="selectedAction">-->
<!--                <v-icon dark>-->
<!--                  mdi-widgets-->
<!--                </v-icon>-->
<!--              </v-btn>-->
<!--            </v-col>-->
<!--          </v-row>-->
        </v-container>
        <v-dialog v-model="campaignDialog">
          <CampaignEditor :key="routeName + selectedAction + campaignKey" :filter="where" :modelKey="routeName"
            :records="selectedItemsIDs" :templateID="selectedAction" @close="campaignDialog = false" />
        </v-dialog>

        <v-data-table :headers="headers" :items="tableItems" :loading="tableIsLoading" :server-items-length="totalCount"
          :items-per-page.sync="itemsPerPage" :search.sync="search" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
          :page.sync="page" :footer-props="footerProps" dense must-sort loading-text="Lade Daten... Bitte warten."
          :show-select="routeModel.tableRowsReadOnly !== true &&routeModel.readOnly !== true && !isInCampaignWorkMode" v-model="selectedItems">

          <template v-slot:body.prepend="{ headers }">
            <InlineSearch :headers="headers" :search-fields="searchFields" :model="listModel" @ilChange="setILsearch" />
          </template>
          <template v-slot:item="{ item, headers, index, isSelected, select }">
            <TableRow :action-delete-disabled="routeModel.actionDeleteDisabled"
              :key="item.id + index + (campaign ? itemsDoneCampaignIds[index].indexOf(campaign.id) !== -1 : false)"
                :action-edit-disabled="routeModel.actionEditDisabled" :tableRowsReadOnly="routeModel.tableRowsReadOnly" :campaignActionButtons="campaignRowActions"
              :campaignDone="campaign ? itemsDoneCampaignIds[index].indexOf(campaign.id) !== -1 : false"
              :headers="headers" :index="index" :isSelected="isSelected" :item="item"
              :read-only="routeModel.readOnly === true" @campaignAction="campaignAction" @deleteItem="deleteItem(item)"
              @editItem="editItem(item)" @rowClick="toggle(isSelected, select)"></TableRow>
          </template>
          <template v-slot:top>
            <v-toolbar flat dense>
              <v-row class="ma-0 pa-0">
                <v-col  xs="12" md="2" class="ma-0 pa-0">
                    <section v-if="$props.routeName === 'WettspieleReferee' || $props.routeName === 'WettspieleScoring'">
                      <h1>{{ routeModel.listTitle }} ({{ items.length }})</h1>
                    </section>
                    <section v-else>
                      <h1>{{ routeModel.listTitle }} ({{ totalCount }})</h1>
                    </section>
                </v-col>
                <v-col xs="12" md="6" class="ma-0 pa-0">
                  <v-row class="ma-0 pa-0" >
                    <v-col xs="12" md="8" class="ma-0 pa-0">
                      <v-row v-if="routeModel.actions && routeModel.actions.roles.includes(userRole)&& !isInCampaignWorkMode"  class="ma-0 pa-0">
                        <v-col md="5" v-if="selectedItems.length !== 0" class="ma-0 pa-0">
                          Aktion für {{ selectedItems.length === 1 ? "einen Datensatz" : selectedItems.length + " Datensätze" }}
                          erstellen:
                        </v-col>
                        <v-col md="5" v-else-if="selectedItems.length === 0" class="ma-0 pa-0">
                          <span>
                          Aktion für Datenbankfilter erstellen:
                          </span>
                          <br>
                          <span>
                          <small>(aktuell {{ totalCount === 1 ? "ein Datensatz" : totalCount + " Datensätze" }})</small>
                          </span>
                        </v-col>
                        <v-col md="6" class="ma-0 pa-0">
                          <SelectList v-model="selectedAction" :default-item="{ name: 'Neue Aktion konfigurieren', id: 'none' }"
                                      :filter="actionsFilter" :model="actionsModel" listName="campaigns" />
                        </v-col>
                        <v-col md="1" class="ma-0 pa-0">
                          <v-btn class="mx-2" fab dark small elevation="2" color="accent" @click="openCampaignEditor" v-if="selectedAction">
                            <v-icon dark>
                              mdi-widgets
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col xs="12" md="4" class="ma-0 pa-0" >
                      <CSVexport v-if="!routeModel.readOnly" v-show="!routeModel.btnCSVExportDisabled"
                                 :campaignId="campaign ? campaign.id : null" :doneText="campaign ? campaign.doneBtnTxt : null"
                                 :unDoneText="campaign ? campaign.undoneBtnTxt : null" :exportName="routeModel['listName']" :filter="where"
                                 :listName="routeModel['listName']" :model="listModel" :sort="orderBy" caption="CSV" />
                      <!--              REF CSV Export-->
                      <CSVRefereeExport v-if="!routeModel.readOnly" v-show="routeModel.btnRefereeExportEnabled" :filter="where"
                                        :model="listModel" caption="CSV-Referee" />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col  xs="12" md="4" class="ma-0 pa-0">
                  <v-row class="ma-0 pa-0" >
                    <v-spacer></v-spacer>
                    <v-btn class="mx-1" fab dark small elevation="2" color="accent" @click="reload">
                      <v-icon dark>
                        mdi-refresh
                      </v-icon>
                    </v-btn>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Suchbegriff eingeben..." outlined rounded dense hide-details ></v-text-field>
                    <FilterEditor :key="routeModel.listTitle + 'fe'" ref="filterEditor" v-model="filters[routeName]"
                                :editFilter="editFilter[routeName]" :model="routeModel" />
                    <CampaignDataInput v-model="editedItem" :campaign="campaign" :campaign-form-dialog="campaignFormDialog"
                      :campaign-forms="campaignForms" @cancel="campaignFormDialog = false" />
                    <v-dialog v-if="isInCampaignWorkMode && commentsDialog" v-model="commentsDialog" max-width="800px">
                      <v-card>
                        <v-card-title>
                          <span>Bemerkungen</span>
                        </v-card-title>
                        <v-card-text>
                          <Sublist :action-create-disabled="false" :action-delete-disabled="false" :action-edit-disabled="false"
                            :model="commentsModel" :multipleRefs="commentsRefs" :parent="commentsItem" listName="comments"
                            listTitle="Bemerkungen" />
                        </v-card-text>
                      </v-card>
                    </v-dialog>

                    <v-dialog v-if="!isInCampaignWorkMode" v-model="dialog"
                      :max-width="editedIndex === -1 || sublists.length === 0 ? '800px' : 'none'">
                      <template v-slot:activator="{ on, attrs }">
                        <!-- v-if="!isRoleScoring" -->
                          <v-btn v-show="!routeModel.actionCreateDisabled && routeModel.readOnly !== true" v-bind="attrs"
                            v-on="on" class="mb-2 mx-1" color="accent" dark :title="newRecord" fab small elevation="2">
                            <v-icon>mdi-plus</v-icon>
<!--                            {{ newRecord }}-->
                          </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                          <div v-if="print" id="printMe">
                            <table style="width:1000px">
                              <thead>
                                <tr>
                                  <th colspan="2" style="text-align:left"><small>Clubname: </small>{{ editedItem.clubname }}
                                  </th>
                                  <th colspan="2" style="text-align:right"><small>Clubnummer: </small>{{ editedItem.clubnummer
                                  }}
                                  </th>
                                </tr>
                                <tr>
                                  <td colspan="2">
                                    <br /><br /><br /><br />
                                    <b>Adresse:</b><br />
                                    {{ editedItem.clubname }}<br />
                                    {{ editedItem.strasse }} {{ editedItem.hausnummer }}<br />
                                    {{ editedItem.plz }} {{ editedItem.ort }}<br /><br />
                                    <small>Telefon:</small><br />
                                    {{ editedItem.telefon }}
                                  </td>
                                  <td colspan="2">
                                    <br /><br /><br /><br />
                                    <b>Kontodaten:</b><br />
                                    <small>Bank:</small><br />
                                    {{ editedItem.bank }}<br />
                                    <small>BIC:</small><br />
                                    {{ editedItem.bic }}<br />
                                    <small>IBAN:</small><br />
                                    {{ editedItem.iban }}<br />
                                    <small>Kontoinhaber:</small><br />
                                    {{ editedItem.kontoinhaber }}
                                  </td>localStorage.getItem('role') == 'scoring'"
                                </tr>
                                <tr class="space">
                                  <td>
                                    <b>Eingetreten am:</b><br />
                                    {{ getLocalDateString(editedItem.eingetreten_am) }}
                                  </td>
                                  <td>
                                    <b>Gemeinnützig:</b><br />
                                    {{ editedItem.gemeinnuetzig ? 'Ja' : 'Nein' }}
                                  </td>
                                  <td>
                                    <b>Mitglied Landessportbund:</b><br />
                                    {{ editedItem.mitglied_landessportbund ? 'Ja' : 'Nein' }}
                                  </td>
                                  <td>
                                    <b>Lochzahl</b><br />
                                    {{ editedItem.lochzahl }}<br /><br />
                                    <small>Lochzahl Kurzplatz:</small><br />
                                    {{ editedItem.lochzahl_kurzplatz }}
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="4" style="white-space:pre-line">
                                    <b>Kommentar:</b><br />
                                    {{ editedItem.kommentar }}
                                  </td>
                                </tr>
                              </thead>
                            </table>
                          </div>
                          <ClubEditor v-if="!print" :xl-cols="editedIndex === -1 || sublists.length === 0 ? 12 : 6"
                            :edited-item="editedItem" :edit-index="editedIndex" :list-model="listModel"
                            :read-only="routeModel.readOnly" :details-read-only="routeModel.detailsReadOnly" :key="'CEKey' + CEKey" @validate="validate"
                            :headline="routeModel.sublistHeadline" :sublists="editedIndex === -1 ? [] : sublists"></ClubEditor>
                          <!--                <v-row v-if="!print">
                                          <v-col cols="12" sm="12" :xl="editedIndex === -1 || sublists.length === 0?12:6">
                                            <EditForm :model="listModel" :is-in-sublist="false" v-model="editedItem" @validation="validate" />
                                          </v-col>
                                          <v-col cols="12" sm="12" :xl="editedIndex === -1?12:6" v-if="editedIndex !== -1 && sublists.length > 0">
                                            <v-card-title>
                                              <span>Vereinsdaten</span>
                                            </v-card-title>
                                            <v-card>
                                              <v-tabs v-model="tab" background-color="primary" dark>
                                                <v-tabs-slider color="accent"></v-tabs-slider>
                                                <v-tab v-for="item in sublists" :key="item.name">
                                                  {{ item.label }}
                                                </v-tab>
                                              </v-tabs>

                                              <v-tabs-items v-model="tab">
                                                <v-tab-item v-for="item in sublists" :key="item.name">
                                                  <v-card flat>
                                                    <v-card-text>
                                                      <Sublist :parent="editedItem" :model="item" :listName="item.name" :listTitle="item.label" :action-create-disabled="item.actionCreateDisabled" :action-delete-disabled="item.actionDeleteDisabled" :action-edit-disabled="item.actionEditDisabled" />
                                                    </v-card-text>
                                                  </v-card>
                                                </v-tab-item>

                                              </v-tabs-items>
                                            </v-card>
                                          </v-col>
                                        </v-row>-->

                        </v-card-text>

                        <v-card-actions>
                          <!--
                          <v-btn v-if="routeModel.readOnly !== true && editedIndex !== -1 && sublists.length > 0"
                            color="success" dark @click="setPrintMode">
                            Drucken
                            <v-icon right dark>
                              mdi-printer
                            </v-icon>
                          </v-btn> -->

                          <v-spacer></v-spacer>
                          <v-btn color="secondary" text @click="close">
                            {{ (routeModel.readOnly === true || routeModel.detailsReadOnly === true)? 'Schließen' : 'Abbrechen' }}
                          </v-btn>

                          <SaveButton v-if="routeModel.readOnly !== true && routeModel.detailsReadOnly !== true" :disabled="!valid || loading"
                            :edited-index="editedIndex" :edited-item="editedItem" :edited-item-i-d="editedItemID"
                            :list-model="listModel" :originalItem="originalItem" :route-model="routeModel" @failed="failed"
                            @success="success"></SaveButton>

                          <!--                  <v-btn v-if="valid" :loading="loading" :disabled="loading" color="accent" class="ma-2 white&#45;&#45;text" @click="save">
                          Speichern
                          <v-icon right dark>
                            mdi-cloud-upload
                          </v-icon>
                        </v-btn>-->

                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="600px">
                      <v-card>
                        <v-card-title class="headline">Wollen Sie diesen Datensatz wirklich löschen?</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
                          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-row>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-card>
      <Forbidden v-if="!isLoggedIn" />
    </template>

  </div>
</template>
<!--<style>
.noBorder.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}
.noBorder.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
}
.hideTextField{
  opacity: 0;
}
.hideTextField:hover,.hideTextField input:focus{
  opacity: 1;
}
</style>-->
<script>
import gql from 'graphql-tag';
import Forbidden from '../views/Forbidden';
//import Sublist from './Sublist';
import gqlCreator from '../../helpers/gqlCreator';
import helpers from '../../helpers/functions';
import functions from '../../helpers/functions';
import FilterEditor from '../views/FilterEditor';
import FilterDisplay from '../views/FilterDisplay';
import CSVexport from '../functions/CSVexport';
import CSVRefereeExport from '../functions/CSVRefereeExport';
import TabLists from "@/components/views/TabLists";
import tableItemsHelper from '@/helpers/tableItemsHelper';
import headersHelper from "@/helpers/headersHelper";
import newDataHelper from "@/helpers/newDataHelper";
import editItemHelper from "@/helpers/editItemHelper";
//import EditForm from "@/components/EditForm";
import TableRow from "@/components/views/TableRow";
import InlineSearch from "@/components/functions/InlineSearch";
import SelectList from "@/components/Forms/SelectList";
import CampaignEditor from "@/components/campaigns/CampaignEditor";
import ClubEditor from "@/components/views/ClubEditor";
import subListHelper from "@/helpers/subListHelper";
import saveItemHelper from "@/helpers/saveItemHelper";
import SaveButton from "@/components/functions/SaveButton";
import updateRecords from "@/helpers/updateRecords";
import Sublist from "@/components/views/Sublist";
//import EditForm from "@/components/Forms/EditForm";
import CampaignDataInput from "@/components/campaigns/CampaignDataInput";

export default {
  components: {
    CampaignDataInput,
    //EditForm,
    SaveButton,
    CampaignEditor,
    SelectList,
    InlineSearch,
    Forbidden,
    FilterEditor,
    FilterDisplay,
    CSVexport,
    CSVRefereeExport,
    TabLists,
    TableRow,
    ClubEditor,
    Sublist
  },
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true
    },
    routeName: {
      type: String,
      required: true,
    },
    campaign: {
      type: Object,
      required: false
    },
    campaignModel: {
      type: Object,
      required: false
    }
  },
  mounted() {
    this.itemsPerPage = this.routeModel.itemsPerPage ? this.routeModel.itemsPerPage : 10;
    if (this.$props.campaign !== undefined) {
      this.filters[this.routeName] = this.campaignFilter;

    }
  },
  data() {
    return {
      CEKey: 0,
      campaignKey: 0,
      commentsDialog: false,
      campaignDialog: false,
      campaignFormDialog: false,
      selectedAction: null,
      actionsModel: {
        listName: 'campaigns',
        typeKey: 'campaign',
        long: 'name',
        value: 'id',
        model: {
          id: false,
          name: 'name'
        }
      },
      selectedItems: [],
      inlineOperators: {},
      threeState: null,
      print: false,
      editFilter: {
        [this.$props.routeName]: []
      },
      filters: {
        [this.$props.routeName]: []
      },
      ILSuche: {},
      valid: false,
      loading: false,
      tab: undefined,
      footerProps: {
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
        "items-per-page-all-text": "Alle",
        "items-per-page-text": "Datensätze je Seite:",
        itemsPerPageOptions: [10, 50, 100],
      },
      items: [],
      search: '',
      itemsConnection: {},
      itemsConnectionQuery: {},
      itemsPerPage: 10,
      first: 0,
      page: 1,
      sortBy: this.defaultSearchField,
      sortDesc: false,
      editedIndex: -1,
      dialog: this.$route.query.new !== undefined,
      dialogDelete: false,
      editedItem: {},
      commentsItem: {},
      error: null,
      boolSearchClicks: {},
      isRoleScoring: false,
      userRole:'',
    };
  },
  computed: {
    campaignForms() {
      //console.log(this.campaign);
      return this.campaign
        ?
        Object.values(this.campaign.settings.modules)
          .filter(module => {
            return module.text && Array.isArray(module.text.formModel) && module.text.formModel.length > 0
          })
          .map(module => module.text.formModel)
        :
        [];
    },
    itemsDoneCampaignIds() {
      return this.items.map(i => {
        let ids = []
        i.campaignsDone.forEach(dc => {
          ids.push(dc.id);
        })
        return ids;
      });
    },
    campaignRowActions() {
      if (this.$props.campaign === undefined) {
        return [];
      } else {
        let actions = [
          {
            label: this.$props.campaign.doneBtnTxt,
            action: "campaignDone",
            active: true,
            group: 'doneUndone'
          },
          {
            label: this.$props.campaign.undoneBtnTxt,
            action: "campaignUndone",
            group: 'doneUndone'
          }
        ];

        if (this.$props.campaign.hasForm) {
          actions.push({
            label: 'Daten eingeben',
            action: "campaignForm",
            group: 'form'
          });
        }

        return actions;
      }
    },
    campaignFilter() {
      return this.isInCampaignWorkMode ? [[{ "campaignsDone_eq": this.$props.campaign.name }], [{ "campaignsUndone_eq": this.$props.campaign.name }]] : [];
    },
    isInCampaignWorkMode() {
      return this.$props.campaign !== undefined;
    },
    filter() {
      return this.filters[this.$props.routeName];
    },
    originalItem() {
      return this.editedIndex !== -1 ? this.items[this.editedIndex] : undefined;
    },
    defaultItem() {
      return {};
    },
    editedItemID() {
      return this.tableItems[this.editedIndex] ? this.tableItems[this.editedIndex].id : null;
    },
    selectedItemsIDs() {
      return this.selectedItems.map(x => x.id);
    },
    actionsFilter() {
      return { dataSource: this.routeModel['mutationKey'], isTemplate: true };
    },
    searchFields() {
      return this.routeModel['searchFields'];
    },
    listModel() {
      return this.routeModel.model;// model[this.$props.routeName].model;
    },
    commentsRefs() {
      return {
        [this.campaign.dataSource]: this.commentsItem.id,
        campaign: this.campaign.id
      };
    },
    commentsModel() {
      let cm = {
        ...require("@/config/models/Vereine").default.model.comments,
        key: 'campaign',
      };
      cm.model.campaign.editable = false;
      return cm;
    },
    routeModel() {
      // console.log(this.$props.routeName)
      return require("@/config/models/" + this.$props.routeName).default;
    },
    actionColumnDisabled() {
      return this.routeModel.actionEditDisabled && this.routeModel.actionDeleteDisabled;
    },
    newRecord() {
      return this.routeModel.newRecordBtnTxt || 'Neuer ' + this.routeModel['listName'].substr(0, this.routeModel['listName'].length - 2);
    },
    tableIsLoading() {
      return this.$apollo.loading || this.loading;
    },
    sublists() {
      // let model = this.listModel;
      return subListHelper(this.listModel,localStorage.getItem('role'));
      /*let subLists = [];
      for (let field in model) {
        const modelFields = Array.isArray(model[field]) ? model[field] : [model[field]];
        let fieldsCollection = {};
        modelFields.forEach((modelField) => {
          if (modelField.subList === true){
            if(fieldsCollection[field] === undefined){
              fieldsCollection[field] = subLists.length;
              subLists.push({
                name: field,
                label: modelField.label,
                model: modelField.model,
                key: modelField.key,
                type: modelField.type,
                editable: modelField.editable,
                actionEditDisabled: modelField.actionEditDisabled,
                actionDeleteDisabled: modelField.actionDeleteDisabled,
                actionCreateDisabled: modelField.actionCreateDisabled,
                newRcrdBtnTxt: modelField.newRcrdBtnTxt,
                mutationKey: modelField.mutationKey,
                createMutation: modelField.createMutation,
                typeKey: modelField.typeKey,
              });
            }else{
              subLists[fieldsCollection[field]].model = {
                ...subLists[fieldsCollection[field]].model,
                ...modelField.model
              };
            }

          }
        });
      }
      return subLists;*/
    },
    newRcrdBtnTxt() {
      return this.listModel.newRcrdBtnTxt || 'Neuer Datensatz';
    },
    listIsEditable() {
      return this.listModel.editable === undefined || this.listModel.editable;
    },
    tableItems() {
      if (this.items !== undefined) {
        // if role referee and route "Alle-Wettspiele" - filter items with isPublic == true
        if (((localStorage.getItem("role") == 'referee' || localStorage.getItem("role") == 'scoring')&& this.$props.routeName == "Alle-Wettspiele") || ((localStorage.getItem("role") == 'verein' && this.$props.routeName == "Alle-Wettspiele"))) {
          let publicItems = []
          for (const item of this.items) {
            if (item.isPublic) {
              publicItems.push(item)
            }
          }
          return tableItemsHelper(this.listModel, publicItems);


          // if role referee and route "Alle-Wettspiele" - filter items with isPublic == true + assigned as a referee (actual user)
        } else if (localStorage.getItem("role") == 'referee' && this.$props.routeName == "WettspieleReferee" )  {
          let publicItems = []
          for (const item of this.items) {
            if (item.referees && item.referees.length) {
              for (const referee of item.referees) {
                if (referee.id == this.userId && item.isPublic)
                  publicItems.push(item)
              }
            }
          }

          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.items = publicItems
          return tableItemsHelper(this.listModel, publicItems);


        }
        else if ( localStorage.getItem("role") === 'scoring' && this.$props.routeName === "WettspieleScoring" ) {
          let publicItems = this.items.filter(
              item => item.competitionScorings.some(
                  s => s.sample ==='Promotion'
              )
          )
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.items = publicItems
        return tableItemsHelper(this.listModel, publicItems);


      }else {
          return tableItemsHelper(this.listModel, this.items);
        }
      }
      else {
        return [];
      }
    },
    formTitle() {
      if (this.listModel.type === 'media') {
        return 'Dateien hochladen';
      }
      if (this.routeModel.readOnly === true) {
        return 'Datensatz-Ansicht'
      }
      return this.editedIndex === -1 ? 'Neuen Datensatz anlegen' : 'Datensatz bearbeiten'
    },
    where() {
      try {
        let model = functions.searchFieldModels(this.listModel, this.headers);
        if (!this.searchFields) return {};
        let whereStatement = {
          _or: []
        };
        for (let i = 0; i < this.searchFields.length; i++) {

          // let pusher = {};
          // let pushIt = true;
          let isILS = false;
          let entry_or = {};
          /**
           * es wird die Inline Suche Priorisiert gegen über der volltext suche
           */
          let suche = '';
          const currentSearchField = this.searchFields[i].replace(/\./g, '-');
          if (this.ILSuche[currentSearchField] !== undefined) {
            suche = this.ILSuche[currentSearchField];
            isILS = true;
          } else {
            suche = this.search
          }

          if (typeof suche === 'string' && suche.trim() === '' || suche === null) continue;

          if (model[currentSearchField] !== undefined && model[currentSearchField].reference !== true) {
            const currentOperator = this.inlineOperators[currentSearchField] !== undefined ? this.inlineOperators[currentSearchField].operator : '';
            switch (model[currentSearchField].type) {
              case 'date':
              case 'datetime':
                if(this.isIsoDate(suche)){
                  if (isILS) {
                    whereStatement[currentSearchField + currentOperator] = suche;
                  } else {
                    entry_or[currentSearchField + currentOperator] = suche;
                    whereStatement._or.push(entry_or);
                  }
                }
                break;
              case 'number':
                if (!isNaN(Number(suche))) {
                  if (isILS) {
                    whereStatement[currentSearchField] = Number(suche);
                  } else {
                    entry_or[currentSearchField] = Number(suche);
                    whereStatement._or.push(entry_or);
                  }
                } else {
                  if (isILS) {
                    whereStatement[currentSearchField] = {};
                  }
                }
                break;
              case 'checkbox':
                if (isILS) {
                  whereStatement[currentSearchField] = Boolean(suche);

                }/*else{
                entry_or[currentSearchField] = Boolean(suche);
                whereStatement._or.push(entry_or);
              }*/
                break;
              default:
                if (isILS) {
                  whereStatement[currentSearchField + "_contains"] = suche;
                } else {
                  entry_or[currentSearchField + "_contains"] = suche;
                  whereStatement._or.push(entry_or);
                }
            }
          } else if (model[currentSearchField] !== undefined) {
            //reference search
            if (isILS) {
              whereStatement[currentSearchField] = {
                _or: []
              };
              if (model[currentSearchField].concat_char) {
                for (let subF in model[currentSearchField].model) {

                  if (typeof model[currentSearchField].model[subF] === 'object') {
                    let entry = subF + ': {'
                    for (let subsubF in model[currentSearchField].model[subF]) {
                      entry += subsubF + '_contains : ' + suche;
                    }
                    entry += '}'
                    whereStatement[currentSearchField]._or.push(entry);
                  } else if (typeof model[currentSearchField].model[subF] === 'string') {
                    let op = '_contains';
                    let _suche = suche;
                    if (subF === 'datum') {
                      op = '_eq';
                      //TODO only valid ISO Date string allowed
                      _suche = ''
                    } else {
                      whereStatement[currentSearchField][subF + op] = _suche;
                    }

                  }
                }


              } else {
                whereStatement[currentSearchField][model[currentSearchField].long + '_contains'] = suche;
              }
            } else {
              entry_or[currentSearchField] = {};
              entry_or[currentSearchField][model[currentSearchField].long + '_contains'] = suche;
              whereStatement._or.push(entry_or);
            }
          }
        }


        const cleaner = (whereStatement) => {

          // wenn es keine volltext suche gibt wird das array rausgenommen
          if (!(whereStatement._or.length > 0)) {
            delete whereStatement._or;
          }
          const removeDashs = (x) => {
            if (x._or !== undefined && x._or.length === 0) {
              delete x._or;
            }
            //remove dash-keys from multiple fields of related list
            for (let wk in x) {
              if (wk.indexOf("-") !== -1) {
                x[wk.split("-")[0]] = {
                  ...x[wk]
                }
                delete x[wk];
              }
            }
          };
          removeDashs(whereStatement);
          for (let wk in whereStatement) {
            //remove empty ors
            if (whereStatement[wk] !== null && whereStatement[wk]._or !== undefined && whereStatement[wk]._or.length === 0) {
              delete whereStatement[wk]._or;
            }
            if (Array.isArray(whereStatement[wk])) {
              whereStatement[wk].forEach(removeDashs);
            }
          }


          return { _where: whereStatement };
        };

        // only ILS and search
        if (this.filter.length <= 0) {
          return cleaner(whereStatement);
        }

        let filterWhere = { _or: [] };
        // let that = this;
        /*[
            [
              {"name_contains":"ee"},
              {"name_contains":"bor"}
            ]
          ]*/
        const that = this;
        this.filter.map(function (x) {
          let andO = {};
          x.map(function (a) {
            for (let key in a) {
              //if reference, make a sub object
              let v = a[key];
              let k = key;
              let field = key.substr(0, key.lastIndexOf("_")).split('-')[0];
              let operator = key.substr(key.lastIndexOf("_"), key.length);
              //if model is reference
              if (model[field] && model[field].reference === true || that.listModel[field].reference === true) {
                v = {};
                k = key.substr(0, key.lastIndexOf("_"));
                if (k.indexOf('-') !== -1) {
                  v[k.split("-")[1] + operator] = a[key];
                } else {
                  model[field] ?
                    v[model[field].long + operator] = a[key]
                    :
                    v[that.listModel[field].long + operator] = a[key];
                }

              }

              andO[k] === undefined
                ?
                andO[k] = v
                :
                typeof andO[k] === 'string'//first and connection, make array
                  ?
                  andO[k] = [andO[k], v]
                  ://array exists add to and array
                  Array.isArray(andO[k])
                    ?
                    andO[k].push(v)
                    :
                    andO[k] = {
                      ...andO[k],
                      ...v
                    };
            }
          });

          filterWhere._or.push(andO);
        });

        whereStatement._or = filterWhere._or.map(function (o) {
          o._or = whereStatement._or;
          return o;
        });

        // console.log(JSON.stringify(whereStatement));
        //combined filters
        /*
        * {

          {
            "clubverwaltungssoftware": {
              "name_contains": "PCC"
            }

        * */
        return cleaner(whereStatement);
      } catch (er) {
        console.log("where error in MainList");
        console.error(er);
        return {};
      }


    },
    /*last: function(){
      return this.first + this.itemsPerPage;
    },*/
    totalCount() {
      try {
        return this.itemsConnection.aggregate ? this.itemsConnection.aggregate.count : 0;
      } catch (er) {
        console.error("error in total count");
        return 0;
      }

    },
    orderBy() {
      try {
        let sort = typeof this.sortBy === 'object' ? this.sortBy[0] : this.sortBy;
        if (this.sortDesc) {
          return sort + ":desc"
        }
        return sort
      } catch (e) {
        console.error("Error in orderBy computed in mainlist");
        return {};
      }
    },
    headers() {
      // return headers from model with actions column
      try {
        let headers = this.actionColumnDisabled ? [] : [{ text: 'Actions', value: 'actions', sortable: false, key: 'x' }];
        let model = this.listModel;

        return headersHelper(model, headers);
      } catch (e) {
        console.error("headers computed error in main list");
        return [];
      }
    },
    newData() {
      return newDataHelper(this.editedItem, this.routeModel, undefined, this.items[this.editedIndex]);
    }
  },
  watch: {
    routeName() {


      if (this.filters[this.$props.routeName] === undefined) {
        this.$set(this.filters, this.$props.routeName, []);
      }
      if (this.editFilter[this.$props.routeName] === undefined) {
        this.$set(this.editFilter, this.$props.routeName, []);
      }
      this.error = null;
      this.search = '';
      this.selectedItems = [];
      this.page = 1;
      this.itemsPerPage = this.routeModel.itemsPerPage ?? 10 ;
      this.first = 0;
      this.sortBy = this.routeModel?this.routeModel.sortBy:null;
      this.sortDesc = this.routeModel?this.routeModel.sortDesc:null;
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    page(current, old) {
      if (current === old + 1) {
        this.first = this.first + this.itemsPerPage;
      } else if (current === 1) {
        this.first = 0;
      } else if (current === old - 1) {
        this.first = this.first - this.itemsPerPage;
      } else {
        //must be last
        this.first = (current * this.itemsPerPage) - this.itemsPerPage;
      }
      if (current !== old) {
        this.$apollo.queries.items.refetch();
      }

    },
    where(current) {
      if (current !== '') {
        this.page = 1;
      }
    }
  },
  errorCaptured(err) {
    this.error = err.message;
    console.error(err);
    return false;
  },
  methods: {
    isIsoDate(str) {
      if (str.includes('T')) {
        // this is datetime
        if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(str)) return false;
      }
      else {
        // this is date only
        if (!/\d{4}-\d{2}-\d{2}/.test(str)) return false;
      }
      const d = new Date(str);
      return d instanceof Date && !isNaN(d.getTime()) && d.toISOString().startsWith(str); // valid date
    },
    openCampaignEditor() {
      this.campaignDialog = true;
    },
    campaignAction(record, action, done) {

      switch (action) {
        case 'campaignForm':
          this.campaignFormDialog = true;
          this.editedItem = this.campaignForms.map((x, i) => {
            return {
              campaign: this.campaign.id,
              form: i,
              record: record
            }
          });
          done();
          break;
        case 'openComments':
          //open comments dialogue
          this.commentsItem = {
            campaign: this.campaign.id
          };
          this.$nextTick(() => this.commentsDialog = true);

          done();
          break;
        default:
          // done/unDone
          updateRecords([
            {
              mutation: gql`
           mutation updateCampaign ($campaignInput: updateCampaignInput){
            updateCampaign(input:$campaignInput){
              campaign{
                id
                name
              }
            }
          }
            `,
              variables: {
                "campaignInput": {
                  "where": { "id": this.$props.campaign.id },
                  "data": { [action === 'campaignDone' ? `${this.$props.campaign.dataSource}sDone` : `${this.$props.campaign.dataSource}sUndone`]: [record.id] }
                }
              }
            },
          ], this).then(() => {
            this.reload();
            done();
          });
      }
    },
    apolloError(er) {
      console.error("Apollo response error", er);
      this.items = [];
      this.itemsConnection = {};
      if (String(er).indexOf('GraphQL error') !== -1) {
        er = 'Ihre Authentifizierung ist abgelaufen. bitte melden Sie sich erneut an.'
      }
      this.error = er;

      localStorage.setItem('token', '');
      localStorage.setItem('role', '');
      this.$emit('changeLoginState');
    },
    toggle(isSelected, select) {
      if (!this.isInCampaignWorkMode) {
        select(!isSelected);
      }
    },
    setILsearch(newSearch, operators) {
      this.ILSuche = {};
      this.inlineOperators = operators;
      this.ILSuche = newSearch;
    },
    validate(state) {
      console.log("validate called");
      this.valid = state;
    },
    filterDisplayClick() {
      this.$refs.filterEditor.edit();
    },
    threeStateIcon(state) {
      switch (state) {
        case 1:
          return 'mdi-arrow-up';
        case -1:
          return 'mdi-arrow-down';
        default:
          return '';
      }
    },
    getLocalDateString(d) {
      return helpers.getLocalDateString(d)
    },
    setPrintMode() {
      this.print = true;
      let that = this;
      this.$nextTick(() => {
        this.$htmlToPaper('printMe', {
          styles: [
            '/print.css'
          ]
        }, () => {
          //console.log('Printing completed or was cancelled!');
          that.print = false;
        });
      });
    },
    reload() {
      const that = this;
      that.loading = true;
      async function refresh() {
        await Promise.all([
          that.$apollo.queries.itemsConnection.refetch(),
          that.$apollo.queries.items.refetch()
        ]);
        that.loading = false;
      }
      refresh();
    },
    isTextField(type) {
      switch (type) {
        case 'select':
        case 'textarea':
        case 'checkbox':
        case 'date':
        case 'datetime':
        case 'media':
          return false;
        default:
          return true;
      }
    },
    editItem(item) {
      //console.log(item);
      this.editedIndex = this.tableItems.indexOf(item);
      this.editedItem = Object.assign({}, this.editedItem, this.items[this.editedIndex]);
      editItemHelper(this.editedItem, this.listModel);
      this.dialog = true
    },
    /*selectItem(selection){
      this.items[selection.itemIndex].isSelected = selection.state;
    },*/
    deleteItem(item) {
      this.editedIndex = this.tableItems.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      let that = this;
      that.loading = true;
      // noinspection GraphQLSchemaValidation,GraphQLUnresolvedReference
      that.$apollo.mutate({
        mutation: gql`mutation ($id: ID!) {
          delete${that.routeModel.typeKey}(
            input:{
              where: {id:$id}
            }){
            ${that.routeModel.mutationKey} {
              id
            }
          }
        }`,
        variables: {
          id: this.tableItems[this.editedIndex].id
        }
      })
        .catch(function (er) {
          that.error = er;
          throw (er);
        })
        .then(function () {
          if (that.listModel.type === 'media') {
            that.items[0].uploads.splice(that.editedIndex, 1);
          } else {
            that.items.splice(that.editedIndex, 1);
            that.itemsConnection.aggregate.count += -1;
          }
          that.success();
        }, that.failed);
    },

    close() {
      this.dialog = false;
      this.error = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    success() {
      this.reload();
      this.close();
      this.closeDelete();
      this.error = null;
      //console.log('success from mainlist');
      this.CEKey++;
    },
    failed(er) {
      console.error(er);

      if (Array.isArray(er.job) && er.job[0].list === 'user') {
        this.error = "Diese E-mail ist schon einem Benutzer zugewiesen.";
      } else {
        this.error = er.message + '\n\n';
      }


      if (er.networkError && er.networkError.result !== undefined && er.networkError.result.errors.length > 1) {
        for (let i = 0; i < er.networkError.result.errors.length; i++) {
          this.error += er.networkError.result.errors[i].message
        }
      }


      this.loading = false;
    },
    parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
    save() {
      this.campaignKey++;
      saveItemHelper(this);
      /*this.loading = true;
      const that = this;

      const seperateDirectReferences = () => {
        let referenceJobs = [];
        for(let field in that.newData){
          if(that.newData[field] !== null && typeof that.newData[field] === 'object' && typeof that.newData[field].id !== undefined){
            let jobObject = {
              list: field,
              value: {...that.newData[field]},
              typeKey: this.listModel[field].typeKey,
              mutationKey: this.listModel[field].mutationKey,
              id: that.newData[field].id
            };
            delete jobObject.value.id;
            delete jobObject.value.__typename;
            referenceJobs.push(jobObject);
            delete that.newData[field];
          }
        }
        return referenceJobs;
      }
      const updateRecord = async (job) => {
        return new Promise(resolve => {
          resolve(

              that.$apollo.mutate({
                mutation: gql`mutation ($id: ID!, $item: edit${job.typeKey}Input) {
                    update${job.typeKey}(
                      input:{
                        where: {id:$id}
                        data: $item
                      }){
                      ${job.mutationKey} {
                        id
                      }
                    }
                  }`,
                variables: {
                  id: job.id,
                  item: job.value
                }
              })

          );
        });
      }

      const updateRecords = async (jobs) => {
        return Promise.all(
            jobs.map((job) => {
                  try{
                    return updateRecord(job)
                  }catch(er){
                    return er;
                  }
                }
            )
        );
      };

      try{
        if (that.editedIndex > -1) {

          //seperate referenced direct fields to update jobs
          let updateJobs = seperateDirectReferences();


          that.$apollo.mutate({
            mutation: gql`mutation ($id: ID!, $item: edit${that.routeModel.typeKey}Input) {
              update${that.routeModel.typeKey}(
                input:{
                  where: {id:$id}
                  data: $item
                }){
                ${that.routeModel.mutationKey} {
                  id
                }
              }
            }`,
            variables:{
               id: that.tableItems[that.editedIndex].id,
               item: that.newData
             }
            }).then(function(){
              //do updateJobs for direct referenced fields
              updateRecords(updateJobs).then(()=>that.success());
              that.$apollo.queries.items.refetch();
              that.$apollo.queries.itemsConnection.refetch();

            },that.failed);
        } else {
          //create record

          //seperate referenced direct fields to create jobs
          let createJobs = seperateDirectReferences();
          let tempCopy = Object.assign({},that.newData);
          let createMutation = that.routeModel.mutationKey;

          /!*
          * {"confirmed":true,"blocked":false,"username":"testvereinsuser","email":"schnihils@me.com","password":"wiqvug-tyStiq-1wyzko","role":"61714b4101e9195a405b2342","golfvereine":"5fcf9eedd16bbba2b7ca8e7e"}
          * *!/

          const createRecord = async (job) => {
            if(job.list === "user"){
              //get roleID
              let roleID;

              await this.$apollo.query({query: gql`query{roles{id name}}`})
                  .then(x=> {
                    const roles = helpers.findValInObj(x,"roles")[0];
                    roles.forEach(r => {
                      if (r.name === 'verein')
                        roleID = r.id;
                    });
                  },this.failed);

             /!* await HTTP.get(process.env.VUE_APP_STRAPI_API_URL+"/users-permissions/roles/").then(x=> {
                //console.log(x);
                const roles = helpers.findValInObj(x,"roles")[0];
                roles.forEach(r => {
                  if (r.name === 'verein')
                    roleID = r.id;
                });
              });*!/

              job.value = {
                ...job.value,
                ...{
                  password: Math.random().toString(36).slice(-8),
                  role: roleID,
                  username: that.editedItem.clubname
                }
              };
            }
            return new Promise(resolve => {
              resolve(

                  that.$apollo.mutate({
                    mutation: gql`mutation ($item: ${job.typeKey}Input) {
                    create${job.typeKey}(
                      input:{
                        data: $item
                      }){
                      ${job.list} {
                        id
                      }
                    }
                  }`,
                    variables: {
                      item: job.value
                    }
                  })

              );
            });
          }

          const createRecords = async (jobs) => {
            return Promise.all(
                jobs.map((job) => {
                      try{
                        return createRecord(job)
                      }catch(er){
                        return er;
                      }
                    }
                )
            );
          };

          let apolloObject = {
            mutation: gqlCreator.create(that.routeModel.typeKey,createMutation,that.listModel),
            variables:{
               item: tempCopy
             }
           };
           if(createMutation === 'user'){
             const REGISTER_USER = gql`
             mutation createUser ($email: String! $password: String! $name: String! $role: ID! $golfvereine: ID){
               createUser(input:{data:{golfvereine: $golfvereine email: $email password: $password username: $name confirmed: true role: $role}}){
                 user{id}
               }
             }
             `
             apolloObject = {
               mutation: REGISTER_USER,
               variables:{
                 name: tempCopy.username,
                 email: tempCopy.email,
                 password: Math.random().toString(36).slice(-8),
                 role: tempCopy.role,
                 golfvereine: tempCopy.golfvereine
               }
              };
           }
          //createRecords(createJobs).then(()=>that.success());
           that.$apollo.mutate(apolloObject)
             .then(function(newRecordResponse){
               //set reference id to createJobs
               const createJobId = helpers.findValInObj(newRecordResponse,'id')[0];
               createJobs.forEach(x=>x.id=createJobId);

               that.$nextTick(function () {
                 createRecords(createJobs).then((createdRecords)=> {
                   //console.log("need to update verein: "+createJobId + " with user=",createdRecords)
                   if(createdRecords.length>0){
                     updateRecord({
                       list: that.routeModel.listName,
                       mutationKey: that.routeModel.mutationKey,
                       typeKey: that.routeModel.typeKey,
                       value:{
                         user: helpers.findValInObj(createdRecords,"id")[0]
                       },
                       id: createJobId
                     }).then(that.success,that.failed);
                   }else{
                     that.success();
                   }

                 });
                that.$apollo.queries.items.refetch();
                that.$apollo.queries.itemsConnection.refetch();
               })
             },that.failed);
        }
      }catch(er){
        this.failed(er);
      }*/
    },
  },
  created() {
    if (localStorage.getItem("token")) this.userId = this.parseJwt(localStorage.getItem("token")).id;
    this.$watch(vm => [vm.sortBy, vm.sortDesc].join(), () => {
      this.$apollo.queries.items.refetch();
      this.$apollo.queries.itemsConnection.refetch();
    })

    if (localStorage.getItem('role') == 'scoring') {
      this.isRoleScoring = true
    } else {
      this.isRoleScoring = false
    }
    this.userRole = localStorage.getItem('role') ;

    this.sortBy = this.routeModel?this.routeModel.sortBy:null;
    this.sortDesc = this.routeModel?this.routeModel.sortDesc:null;
  },
  updated(){

  },
  apollo: {
    items: {
      error(er) {
        this.apolloError(er);
      },
      skip() {
        return this.routeModel['listName'] === 'none' || this.error !== null;
      },
      query() {
        return gqlCreator.query(this.listModel, this.routeModel['listName'], {
          qVars: 'sort: $sort, start: $start, limit: $limit, where: $where',
          qSpec: '$sort: String, $start: Int, $limit: Int, $where: JSON'
        });
      },
      variables() {
        return {
          sort: this.orderBy,
          start: this.first,
          limit: this.itemsPerPage,
          where: this.where
        }
      },
      update(data) {
        return data[this.routeModel['listName']];
      },
    },
    itemsConnection: {
      error(er) {
        this.apolloError(er);
      },
      skip() {
        return this.routeModel['listName'] === 'none'
      },
      query() {
        return gqlCreator.query('count', this.routeModel['listName']);
      },
      variables() {
        // console.log(this.where)
        return { where: this.where }
        /*return {//and connections are working like this...
          where: {
            _where: [
              {
                "league.name_contains": "dame"
              },
              {
                "league.name_ncontains": "DGL"
              }
            ]
          }
        }*/
      },
      update(data) {
        return data[this.routeModel['listName'] + 'Connection'];
      },
    }
  }
}
</script>
