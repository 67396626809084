<template>
  <v-card>
    <v-card-title>
      Verfügbare Bilder {{ files === undefined ? '' : 'und Dateien' }}
    </v-card-title>
    <v-card-text>
      <v-dialog
          v-model="uploadDialog"
          max-width="800px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Bild hochladen</span>
          </v-card-title>

          <v-card-text>

            <EditForm v-model="editedItem" :edit-item-index="-1" :is-in-sublist="true" :model="listModel"
                      :rules="rules" @validation="validate"/>

            <v-flex v-if="error" class="text-xs-center" mt-5>
              <v-alert style="white-space:pre-wrap"
                       type="error"
              >{{error}}</v-alert>
            </v-flex>

          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="secondary"
                text
                @click="close"
            >
              Abbrechen
            </v-btn>

            <v-btn
                v-if="valid"
                :loading="loading"
                :disabled="loading"
                color="accent"
                class="ma-2 white--text"
                @click="save"
            >
              Senden
              <v-icon
                  right
                  dark
              >
                mdi-cloud-upload
              </v-icon>
            </v-btn>
          </v-card-actions>
          <v-flex v-if="error" class="text-xs-center" mt-5>
            <v-alert style="white-space:pre-wrap"
                     type="error"
            >{{error}}</v-alert>
          </v-flex>
        </v-card>
      </v-dialog>

      <!--      <EditForm :edit-item-index="-1" v-model="externalImageForm" :is-in-sublist="true" :model="{url:{label:'Bild-URL',required:true}}"
                      :rules="rules" @validation="validateImageURL"/>
            <v-btn
                :disabled="!validUrl"
                color="accent"
                class="ma-2 white&#45;&#45;text"
                @click="setImage"
            >
              Übernehmen
            </v-btn>-->

      <v-row>
        <v-col
            v-for="(image,i) in justImages"
            :key="'imageCol'+i"
            class="d-flex child-flex"
            cols="4"
        >
          <v-card>

            <v-img
                v-if="getFileThumb(image).indexOf('.pdf') === -1"
                :src="backend + getFileThumb(image)"
                aspect-ratio="1"
                class="grey lighten-2 pointer"
                @click="imageClick(backend + image.url)"
            >
              <template v-slot:placeholder>
                <v-row
                    align="center"
                    class="fill-height ma-0"
                    justify="center"
                >
                  <v-progress-circular
                      color="grey lighten-5"
                      indeterminate
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-icon v-else
                    x-large
                    @click="imageClick(backend + image.url)"
            >
              mdi-paperclip
            </v-icon>

            <v-card-subtitle x-small>
              {{ image.caption }}
            </v-card-subtitle>
          </v-card>

        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
          color="accent"
          @click="upload"
      >
        Hochladen
      </v-btn>
    </v-card-actions>
  </v-card>


  <!--<v-dialog v-model="dialogDelete" max-width="600px">
    <v-card>
      <v-card-title class="headline">Wollen Sie diesen Datensatz wirklich löschen?</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
        <v-btn color="blue darken-1" text @click="deleteItemConfirm">Löschen</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-flex v-if="error" class="text-xs-center" mt-5>
        <v-alert style="white-space:pre-wrap"
                 type="error"
        >{{error}}</v-alert>
      </v-flex>
    </v-card>
  </v-dialog>-->

</template>
<script>
import gqlCreator from "@/helpers/gqlCreator";
import rules from "@/helpers/rules";
import gql from "graphql-tag";
import {HTTP} from "@/axios";
import newDataHelper from "@/helpers/newDataHelper";


export default {
  components: {EditForm: () => import('@/components/Forms/EditForm')},
  props: [
    "collection",
    "model",
    "files",
    "refName",
    "refId"
  ],
  name: 'ImageGallery',
  data() {
    return {
      images: [],
      backend: process.env.VUE_APP_STRAPI_API_URL,
      error: null,
      rules: rules,
      valid: false,
      uploadDialog: false,
      loading: false,
      editedItem: {},
    };
  },
  computed: {
    fileExts() {
      let exts = [".PNG", ".GIF", ".png", ".jpg", ".gif", ".jpeg", ".JPEG", ".JPG"];
      if (this.$props.files !== undefined) {
        exts.push(".pdf", ".PDF");
      }
      return exts;
    },
    newData() {
      return newDataHelper(this.editedItem, {model: this.listModel}, {});
    },
    justImages() {
      return this.images.map(image => {
        return image.uploads || image;
      });
    },
    listName() {
      return this.$props.collection || "images";
    },
    listModel() {
      return this.$props.model || {
        id: false,
        uploads: {
          type: "media",
          model: {
            id: false,
            caption: "Beschreibung",
            url: "Vorschau",
            formats: false
          }
        }
      };
    }
  },
  methods: {
    getFileThumb(image) {
      const uploads = image.uploads || image;
      const fileThumbUrl = uploads.formats && uploads.formats.small !== undefined ? uploads.formats.small.url : uploads.formats ? uploads.formats.thumbnail.url : uploads.url;
      return fileThumbUrl ? fileThumbUrl : '';
    },
    failed(er) {
      console.error(er);
      this.error = er.message + '\n\n';
      if (er.networkError !== undefined && er.networkError.result !== undefined && er.networkError.result.errors.length > 1) {
        for (let i = 0; i < er.networkError.result.errors.length; i++) {
          this.error += er.networkError.result.errors[i].message
        }
      }
      this.loading = false;
    },
    success(){
      this.loading = false;
      this.close();
      //this.closeDelete();
      this.error = null;
    },
    upload(){
      this.uploadDialog = true;
    },
    imageClick (url) {
      this.$emit('imageClick',url);
    },
    validate(state){
      this.valid = state;
    },
    close () {
      this.uploadDialog = false;
      this.error = null;
    },
    save() {
      this.loading = true;
      try{
        let that = this;

        //create record
        let tempCopy = Object.assign({},that.newData);
        if (that.listModel.uploads.type === 'media') {
          const formData = new FormData();
          tempCopy.uploads.map(function (x) {
            formData.append('files', x, x.name);
            return x;
          });
          if (that.$props.refName) {
            formData.append('refId', that.$props.refId);
            formData.append('ref', that.$props.refName);
            formData.append('field', 'uploads');
          }
          // formData.append('refId', this.images[0].id);
          // formData.append('ref', 'Images');
          // formData.append('field', 'uploads');
          //formData.append('caption', that.newData.caption);

          //reset from local storage in case of user switch without reloading
          HTTP.defaults.headers['Authorization'] = localStorage.getItem('token');
          HTTP.post('/upload',
              formData
          ).then(function (re) {
            //caption was not empty must be set after upload
            if (that.newData.caption !== '' || that.editedItem.caption !== '') {
              let updateO = {
                caption: that.newData.caption || that.editedItem.caption
              };
              let updateCaption = function (files) {
                //console.log("should create image entry for id: " + files[0].id);

                const apolloObject = {
                  mutation: gql`mutation($id: ID!){
                      createImage (
                        input: {data:{
                          uploads: $id
                        }}
                      ){image{
                        id
                        uploads{
                          formats
                        }
                      }}
                    }`,
                  variables:{
                    id: files[0].id
                  }
                };
                that.$apollo.mutate(apolloObject)
                    .then(function(re){
                      that.$nextTick(function () {
                        // console.log(re.data);
                        that.images.push(re.data.createImage.image);
                      })
                    },that.failed);

                that.$apollo.mutate({
                  mutation: gql`mutation ($id: ID!, $item: FileInfoInput!) {
                    updateFileInfo(
                      id: $id
                      info: $item
                    ){
                      id
                    }
                  }`,
                  variables:{
                    id: files[0].id,
                    item: updateO
                  }
                }).then(function(){
                  files.shift();
                  if(files.length > 0){
                    updateCaption(files);
                  }else{
                    that.$apollo.queries.images.refetch();
                    that.success();
                  }
                },that.failed);
              };
              updateCaption(re.data);
            }else{
              //no caption
              that.$nextTick(function () {
                that.images[0] = that.items[0] || {uploads:[]};
                that.images[0].uploads = that.images[0].uploads.concat(re.data);
                that.success();
              });
            }
          })
              .catch(function(er){
                that.failed(er);
              });

        }
      }catch(er){
        console.error(er);
        this.failed(er);
      }
    },
  },
  apollo: {
    images: {
      query() {
        if (this.$props.refId) {
          return gql(`query ($where: JSON!) {
                    campaigns(where: $where) {
                      id
                      uploads {
                        id
                        name
                        caption
                        url
                        formats
                        __typename
                      }
                      __typename
                    }
                  }
                  `)
        } else {
          return gqlCreator.query(this.listModel, this.listName);
        }
      },
      variables() {
        if (this.$props.refId) {
          return {
            "where": {
              "id": this.$props.refId
            }
          }
        } else {
          return {
            "where": {
              "uploads": {
                "ext_in": this.fileExts
              }
            }
          }
        }

      },
      update (data) {
        if (this.$props.refId) {
          return data.campaigns[0].uploads;
        } else {
          return data.images;
        }

      },
    },
  }
}
</script>
