export default (state) => {
  switch (state){
    case 1:
      return 'mdi-arrow-up';
    case -1:
      return 'mdi-arrow-down';
    default:
      return 'mdi-circle-medium';
    }
}

