<template>
  <div>
    <v-card class="mx-auto">
      <v-data-table :headers="headers" :items="tableItems" :server-items-length="totalCount" :hide-default-footer="true"
        disable-filtering disable-pagination disable-sort :loading="loading" loading-text="Lade Daten... Bitte warten.">
        <template v-slot:top>
          <v-btn class="mx-2 float-right" fab dark small color="accent" @click="reload">
            <v-icon dark>
              mdi-refresh
            </v-icon>
          </v-btn>
        </template>

        <!-- <template v-for="(field, i) in checkBoxSlots" v-slot:item[field]="{ item }">
          <v-simple-checkbox v-model="item[field]" disabled :key="'cb'+i"></v-simple-checkbox>
        </template>-->

        <template v-slot:item="{ item, headers, index }">
          <tr :key="'tr' + index">
            <td v-for="head in headers" :key="index + head.value + 'rtd'">
              <v-simple-checkbox v-if="head.text.type === 'checkbox'" :value="item[head.value]" disabled></v-simple-checkbox>
              <v-simple-checkbox v-if="head.type === 'checkbox'" :value="item[head.value]" disabled></v-simple-checkbox>
              <span v-else-if="head.type === 'html'" style="white-space: pre-line" v-html="item[head.value]"></span>
              <template v-else-if="head.value === 'actions'">
                <v-icon
                  v-show="!hidden && !actionEditDisabled && (!model.actionEditDisabled || model.actionEditDisabled === undefined)"
                  class="mr-2" small @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small @click="deleteItem(item)" v-show="!actionDeleteDisabled">
                  mdi-delete
                </v-icon>
                <v-icon small @click="unlinkItem(item)" v-show="showUnlink">
                  mdi-delete
                </v-icon>
                <v-icon v-show="actionEditDisabled" small @click="view(item)">
                  mdi-eye
                </v-icon>
              </template>

              <v-icon v-else-if="head.display === 'threeState'">
                {{ threeStateIcon(item[head.value]) }}
              </v-icon>

              <template v-else-if="head.value === 'caption'">
                <div style="white-space:pre">{{ item.caption }}</div>
              </template>

              <template v-else-if="head.value === 'url'">
                <v-avatar color="primary" @click="download(item)" style="cursor:pointer">
                  <img v-if="item.formats" :src="backend + item.formats.thumbnail.url" alt="John">
                  <v-icon v-if="!item.formats">
                    mdi-file-pdf
                  </v-icon>
                </v-avatar>
              </template>

              <template  v-else-if="head.type === 'link'" >
                <a target="_blank" :href="item[head.value]">
                  {{ item[head.value] }}
                </a>
              </template>
              <v-card-text v-else>
                {{ head.text.type === 'checkbox' ? "" : item[head.value] }}
              </v-card-text>
            </td>
          </tr>
        </template>

        <template v-slot:footer>
          <v-toolbar flat>

            <CSVexport v-if="model.listName === 'campaignsInputs'" :campaignId="null" :filter="{ campaign: parent.id }"
              :model="model.model" caption="CSV-Export" doneText="erledigt" exportName="campaignsInputs"
              listName="campaignsInputs" sort="name:asc" unDoneText="undoneText" />

            <v-dialog v-model="addDialog" v-if="assignRcrdBtn" max-width="800px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mb-2" color="accent" dark v-bind="attrs" v-on="on">
                  {{ assignRcrdBtnTxt }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">Den Vereinsdaten ein vorhandenes Hotel zuweisen</span>
                </v-card-title>

                <v-card-text>
                  <v-autocomplete label="Hotelauswahl" v-model="ac" :items="hotels" item-value="id" item-text="name"
                    @change="(event) => selectHotel(event)"></v-autocomplete>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" text @click="closeAssign">
                    Schließen
                  </v-btn>

                  <v-btn :loading="loading" :disabled="loading" color="accent" class="ma-2 white--text"
                    @click="selectSubmit">
                    Hotel zuweisen
                    <v-icon right dark>
                      mdi-plus
                    </v-icon>
                  </v-btn>

                </v-card-actions>

                <v-snackbar v-model="snackbarAssigned" :timeout="2500" color="success" icon="check_circle"
                  mode="multi-line" position="top" title="Success" visible: true>
                  Das Hotel wurde dem Verein erfolgreich zugewiesen.
                </v-snackbar>
              </v-card>
            </v-dialog>

            <v-dialog v-model="assignRefereeDialog" v-if="assignRefereeRcrdBtn" max-width="800px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mb-2" color="accent" dark v-bind="attrs" v-on="on">
                  {{ assignRefereeRcrdBtnTxt }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">Hotelbuchung für Referee/Scoring eintragen</span>
                </v-card-title>

                <v-card-text>
                  <v-autocomplete
                      label="Refereeauswahl"
                      v-model="dc"
                      :items="referees"
                      item-value="id" :item-text="getSelectHotelbuchungItemText"
                      @change="(event) => selectReferee(event)"
                  >
                    <template #item="{ item }">
                      <v-row :class="((parent.referees.find(e => e.username === item.name))?'secondary white--text':'')" style="margin: 0" >

                        <v-list-item-avatar v-if="item.role === 'referee'">
                          <v-icon :dark="(!!parent.referees.find(e => e.username === item.name))">mdi-whistle</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-avatar v-if="item.role === 'scoring'">
                          <v-icon :dark="(!!parent.referees.find(e => e.username === item.name))" >mdi-clipboard-list</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title v-html="item.name"></v-list-item-title>
                          <v-list-item-subtitle v-html="item.role.charAt(0).toUpperCase() + item.role.slice(1)" :class="((!!parent.referees.find(e => e.username === item.name))?'white--text':'')"></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-row>
                    </template>
                  </v-autocomplete>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" text @click="closeAssign">
                    Schließen
                  </v-btn>

                  <v-btn :loading="loading" :disabled="loading" color="accent" class="ma-2 white--text"
                    @click="selectDCSubmit">
                    Referee zuweisen
                    <v-icon right dark>
                      mdi-plus
                    </v-icon>
                  </v-btn>

                </v-card-actions>

                <v-snackbar v-model="snackbarRefereeAssignedSuccess" :timeout="2500" color="success" icon="check_circle"
                  mode="multi-line" position="top" title="Success" visible: true>
                  Die Hotelbuchung für den gewählten Referee wurde erfolgreich eingetragen.
                </v-snackbar>

                <v-snackbar v-model="snackbarRefereeAssignedError" :timeout="2500" color="red" icon="cross"
                  mode="multi-line" position="top" title="Success" visible: true>
                  Die Hotelbuchung für den gewählten Referee ist schon eingetragen.
                </v-snackbar>

              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogUnlink" max-width="600px">
              <v-card>
                <v-card-title class="headline">Möchten Sie diese Hotelzuweisung aufheben?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeUnlink">Abbrechen</v-btn>
                  <v-btn color="blue darken-1" text @click="unlinkItemConfirm">Aufheben</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogRefereeUnlink" max-width="600px">
              <v-card>
                <v-card-title class="headline">Möchten Sie die Hotelbuchung aufheben?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeUnlink">Abbrechen</v-btn>
                  <v-btn color="blue darken-1" text @click="unlinkRefereeItemConfirm">Aufheben</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="editDialog" max-width="800px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mb-2" style="margin-left: 10px;" color="accent" dark v-bind="attrs" v-on="on"
                  v-if="createIsEnabled">
                  {{ newRcrdBtnTxt }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <EditForm :key="editedIndex + 'editForm'" v-model="editedItem" :edit-item-index="editedIndex"
                    :is-in-sublist="true" :model="model.model" @validation="validate" />
                  <v-flex v-if="error" class="text-xs-center" mt-5>
                    <v-alert style="white-space:pre-wrap" type="error">{{ error }}
                    </v-alert>
                  </v-flex>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" text @click="close">
                    Abbrechen
                  </v-btn>

                  <v-btn v-if="valid" :loading="loading" :disabled="loading" color="accent" class="ma-2 white--text"
                    @click="save">
                    Senden
                    <v-icon right dark>
                      mdi-cloud-upload
                    </v-icon>
                  </v-btn>
                </v-card-actions>
                <v-flex v-if="error" class="text-xs-center" mt-5>
                  <v-alert style="white-space:pre-wrap" type="error">{{ error }}
                  </v-alert>
                </v-flex>
              </v-card>
            </v-dialog>

            <v-dialog v-if="showImportBtn" v-model="importToListDialog" max-width="800px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ml-2" color="accent" dark small v-bind="attrs" v-on="on">
                  Daten in Liste importieren
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">Liste und Zielfelder auswählen</span>
                </v-card-title>

                <v-card-text>
                  <EditForm :key="editedIndex + 'listImportForm'" v-model="dataImportItem" :edit-item-index="editedIndex"
                    :is-in-sublist="true" :model="importModel" @validation="validate" />
                  <v-flex v-if="error" class="text-xs-center" mt-5>
                    <v-alert style="white-space:pre-wrap" type="error">{{ error }}
                    </v-alert>
                  </v-flex>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" text @click="importToListDialog = false">
                    Abbrechen
                  </v-btn>

                  <v-btn v-if="valid" :disabled="loading" :loading="loading" class="ma-2 white--text" color="accent"
                    @click="save">
                    In Liste Importieren
                    <v-icon dark right>
                      mdi-cloud-upload
                    </v-icon>
                  </v-btn>
                </v-card-actions>
                <v-flex v-if="error" class="text-xs-center" mt-5>
                  <v-alert style="white-space:pre-wrap" type="error">{{ error }}
                  </v-alert>
                </v-flex>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogDelete" max-width="600px">
              <v-card>
                <v-card-title class="headline">Wollen Sie diesen Datensatz wirklich löschen?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Löschen</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
                <v-flex v-if="error" class="text-xs-center" mt-5>
                  <v-alert style="white-space:pre-wrap" type="error">{{ error }}
                  </v-alert>
                </v-flex>
              </v-card>
            </v-dialog>

            <v-dialog v-model="viewDialog" max-width="1200px">
              <v-card>
                <v-card-title class="headline">Datensatzansicht</v-card-title>
                <v-card-text>
                  <DataView :item="editedItem" />
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Schließen</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon v-if="listIsEditable" small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small class="mr-2" @click="deleteItem(item)">
            mdi-delete
          </v-icon>
          <v-icon small class="mr-2" v-if="model.type === 'media'" @click="download(item)">
            mdi-download
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>

import axios from "axios";
import gqlCreator from '@/helpers/gqlCreator';
import gql from 'graphql-tag';
import { HTTP } from '@/axios';
import tableItemsHelper from "@/helpers/tableItemsHelper";
import headersHelper from "@/helpers/headersHelper";
import newDataHelper from "@/helpers/newDataHelper";
import editItemHelper from "@/helpers/editItemHelper";
import EditForm from "@/components/Forms/EditForm";
import DataView from "@/components/views/DataView";
import CSVexport from '../functions/CSVexport';
import helpers from "@/helpers/functions";


export default {

  components: { EditForm, DataView, CSVexport },
  props: {
    model: {
      type: Object,
      required: true,
    },
    parent: {
      type: Object,
      required: true
    },
    defaultSearchField: {
      type: String,
      required: false
    },
    listName: {
      type: [String, Function],
      required: true
    },
    actionDeleteDisabled: {
      type: Boolean,
      required: false
    },
    actionUnlinkEnabled: {
      type: Boolean,
      required: false
    },
    actionEditDisabled: {
      type: Boolean,
      required: false
    },
    actionCreateDisabled: {
      type: Boolean,
      required: false
    },
    multipleRefs: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      backend: process.env.VUE_APP_STRAPI_API_URL,
      items: [],
      dataImportItem: {
        listName: 'competitionApplications',
        Wettspiele: 'name',
        golfvereine: 'applicants'
      },
      search: '',
      searchField: [],
      itemsConnection: {},
      itemsConnectionQuery: {},
      itemsPerPage: -1,
      first: 0,
      page: 1,
      sortBy: '',
      sortDesc: false,
      editedIndex: -1,
      editDialog: false,
      addDialog: false,
      importToListDialog: false,
      dialogDelete: false,
      dialogUnlink: false,
      dialogRefereeUnlink: false,
      editedItem: {},
      defaultItem: {},
      loading: false,
      valid: false,
      error: null,
      viewDialog: false,
      authToken: null,
      hotels: [],
      selectedHotel: null,
      assignedHotelIds: [],
      assignRefereeDialog: false,
      // competition: "64c7bb7cc73482ab50d3cc1c",
      // competitionId: "64c7bb7cc73482ab50d3cc1c",
      referees: [],
      selectedReferee: null,
      assignedRefereeIds: [],
      itemTooUnlink: null,
      snackbarAssigned: false,
      snackbarRefereeAssignedSuccess: false,
      snackbarRefereeAssignedError: false,
      showUnlink: null,
      hidden: false,
      ac: null,
      dc: null,
      role: null,
    };
  },
  mounted() {
    this.authToken = localStorage.getItem("token");
    this.role = localStorage.getItem("role");
    this.initiateState();
  },
  computed: {
    showImportBtn() {
      try {
        const campaignFormModel = helpers.findValInObj(this.$props.parent, 'formModel')[0];
        const campaignFormHasListBasedInput = helpers.findValInObj(campaignFormModel, 'listName');
        return this.$props.listName === 'campaignsInputs' && campaignFormHasListBasedInput !== null;
      } catch (er) {
        return false;
      }
    },
    golfvereinId() {
      // If it is a golf club, enter the id (we need this id later to assign hotels)
      if (this.$props.model.key == "golfvereine" || this.$props.model.key == "golfvereines") {
        return this.$props.parent.id
      } else {
        return false
      }
    },
    competitionId() {
      // if it is a competition, set the id (we need this id later to assign referees)
      if (this.$props.model.key == "competition" || this.$props.model.key == "competitions") {
        return this.$props.parent.id
      } else {
        return false
      }
    },
    importModel() {
      //todo: cretae list fields
      if (this.items[0] === undefined) {
        return [];
      }

      const dataSource = helpers.findValInObj(this.$props.parent, 'dataSource')[0];

      const fieldNames = [
        { v: 'applicants', d: 'Bewerber Golfvereine' },
        { v: 'name', d: 'Wettspiel' },
        { v: 'referee_applicants', d: 'Bewerber Schiedsrichter' }
      ];

      let campaignInputs = {
        listName: {
          label: 'Liste',
          type: 'select',
          name: 'listName',
          required: true,
          options: [{
            v: 'competitionApplications',
            d: 'Wettspiele'
          }]
        },
        season: {
          label: "Saison",
          query: true,
          type: "select",
          reference: true,
          listName: "seasons",
          mutationKey: "seasons",
          value: "name",
          short: "name",
          long: "name",
          required: true,
          model: {
            id: false,
            name: { label: "Saison" }
          }
        }
      };
      campaignInputs[dataSource] = {
        label: dataSource,
        type: 'select',
        required: true,
        options: fieldNames
      };

      Object.keys(this.items[0]["value"]).forEach(fieldKey => {
        campaignInputs[fieldKey] = {
          label: fieldKey,
          type: 'select',
          options: fieldNames,
          required: true,
          condition(formItem) {
            return formItem.listName && formItem.listName !== ''
          }
        };
      });

      return campaignInputs;
    },
    createIsEnabled() {
      return !this.actionCreateDisabled && (!this.model.actionCreateDisabled || this.model.actionCreateDisabled === undefined);
    },
    newRcrdBtnTxt() {
      return this.$props.model.newRcrdBtnTxt || 'Neuer Datensatz';
    },
    assignRcrdBtn() {
      if (
        this.$props.model.name == "hotels" && this.$props.model.assignRcrdBtn == true
      ) {
        return this.$props.model.assignRcrdBtn || true;
      } else {
        return false
      }
    },
    assignRcrdBtnTxt() {
      return this.$props.model.assignRcrdBtnTxt || 'Vorhandenes Hotel zuweisen';
    },
    assignRefereeRcrdBtn() {
      if (this.$props.model.name !== "hotelbuchungs") return false;
      if (this.$props.model.assignRefereeRcrdBtn_allowedRoles === undefined) return false;
      if(this.$props.model.assignRefereeRcrdBtn_allowedRoles.indexOf(this.role) !== -1){
        return this.$props.model.assignRefereeRcrdBtn === true;
      }
      return false;
    },
    assignRefereeRcrdBtnTxt() {
      return this.$props.model.assignRefereeRcrdBtnTxt || 'Referee zuweisen';
    },
    listIsEditable() {
      return this.$props.model.editable === undefined || this.$props.model.editable !== false;
    },
    tableItems() {
      return tableItemsHelper(this.$props.model.model, this.items);
    },
    /*formFields(){
      let model = this.$props.model.model;
      let formObject = {};
      //let createVerein = this.editedIndex === -1;
      for(let field in model){
        if(isEditable(model[field])){
          formObject[field] = {
            name: field,
            label: model[field].text || (typeof model[field] === "string"?model[field]:field),
            type: fieldType(model[field]),
            colspan: model[field].colspan || 6,
            required: model[field].required || false,
            rules: this.$props.rules[model[field].rules] || [],
            model: model[field] || null,
            listName: model[field].listName
          };
          if(fieldType(model[field])==='media'){
            formObject['caption']={
              name: "caption",
              label: "Beschreibung",
              type: "textarea",
              colspan: 12,
              required: true,
              rules: this.$props.rules['notEmptyRules'],
              model: null,
              listName: model[field].listName
            };
          }
          if(formObject[field].required){
            formObject[field].label+='*';
          }
        }
      }
      return formObject;
    },*/
    formTitle() {
      if (this.model.type === 'media') {
        return 'Dateien hochladen';
      }
      return this.editedIndex === -1 ? 'Neuen Datensatz anlegen' : 'Datensatz bearbeiten'
    },
    where() {
      let whereStatement = {};
      if (this.$props.multipleRefs) {
        whereStatement = { ...this.$props.multipleRefs };
      } else if (this.$props.parent) {
        const filterField = this.$props.model.key || "id_in";
        whereStatement[filterField] = this.$props.model.staticReference === true ? this.$props.parent["records"] : this.$props.parent.id;
      }
      return whereStatement;
    },
    totalCount: function () {
      //return 0;
      return this.itemsConnection.aggregate ? this.itemsConnection.aggregate.count : 0;
    },
    orderBy() {
      if (this.sortDesc) {
        return this.sortBy + ":desc"
      }
      return this.sortBy
    },
    headers() {
      // return headers from model with actions column
      let headers = this.actionDeleteDisabled && this.actionEditDisabled && this.model.name !== 'emails' ? [] : [{
        text: 'Actions',
        value: 'actions',
        sortable: false,
        width: '120'
      }];
      let model = this.$props.model.model;
      return headersHelper(model, headers, true);
    },
    cListName() {
      return typeof this.$props.listName === "function" ? this.$props.listName(this.$props.parent) : this.$props.listName
    }
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    page(current, old) {
      if (current > old) {
        this.first = this.first + this.itemsPerPage;
      } else if (current === 1) {
        this.first = 0;
      } else {
        this.first = this.first - this.itemsPerPage;
      }
      if (current !== old) {
        this.$apollo.queries.items.refetch();
      }

    },
    search(current) {
      if (current !== '') {
        this.page = 1;
      }
    }
  },
  errorCaptured(err) {
    this.error = err.message;
    console.error(err);
    return false;
  },
  methods: {
    getSelectHotelbuchungItemText(item) {
      return `(${item.role.charAt(0).toUpperCase() + item.role.slice(1)}) ${item.name}`;
    },
    generateNewDataObject() {
      let newItem = newDataHelper(this.editedItem, this.$props.model, this.$props.parent);
      if (this.$props.multipleRefs) {
        newItem = { ...newItem, ...this.$props.multipleRefs };
      }
      return newItem;
    },
    selectHotel(hotelId) {
      this.selectedHotel = hotelId
    },
    selectReferee(refereeId) {
      this.selectedReferee = refereeId
    },
    async selectSubmit() {
      const isAssigned = await this.isAlreadyAssigned(this.selectedHotel)
      if (!isAssigned) await this.assignHotel(this.selectedHotel)
    },
    async selectDCSubmit() {
      // await this.assignReferee(this.competition, this.selectReferee)
      const isAssigned = await this.isRefereeAlreadyAssigned(this.selectedReferee)
      if (!isAssigned) await this.assignReferee(this.selectedReferee)
    },
    view(item) {
      this.editedIndex = this.tableItems.indexOf(item);
      if (this.model.type === 'media') {
        this.editedItem = Object.assign({}, this.editedItem, this.items[0].uploads ? this.items[0].uploads[this.editedIndex] : this.items[0].attachment);
      } else {
        this.editedItem = Object.assign({}, this.editedItem, this.items[this.editedIndex]);
      }
      this.viewDialog = true;
    },
    validate(state) {
      // console.log("validate");
      // console.log(state)
      this.valid = state;
    },
    threeStateIcon(state) {
      switch (state) {
        case 1:
          return 'mdi-arrow-up';
        case -1:
          return 'mdi-arrow-down';
        default:
          return '';
      }
    },
    download(item) {
      window.open(this.backend + item.url);
    },
    reload() {
      const that = this;
      that.loading = true;
      async function refresh() {
        await Promise.all([
          that.$apollo.queries.itemsConnection.refetch(),
          that.$apollo.queries.items.refetch()
        ]);
        that.loading = false;
      }
      refresh();
    },
    isTextField(type) {
      switch (type) {
        case 'select':
        case 'textarea':
        case 'checkbox':
        case 'date':
        case 'datetime':
        case 'media':
          return false;
        default:
          return true;
      }
    },
    editItem(item) {
      this.editedIndex = this.tableItems.indexOf(item);
      if (this.model.type === 'media') {
        this.editedItem = Object.assign({}, this.editedItem, this.items[0].uploads[this.editedIndex]);
      } else {
        this.editedItem = Object.assign({}, this.editedItem, this.items[this.editedIndex]);
      }

      if (this.$props.multipleRefs) {
        this.editedItem = { ...this.editedItem, ...this.$props.multipleRefs }
      }

      editItemHelper(this.editedItem, this.$props.model.model);

      this.editDialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.tableItems.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      let that = this;
      that.loading = true;
      that.$apollo.mutate({
        mutation: gql`mutation ($id: ID!) {
          delete${this.$props.model.typeKey}(
            input:{
              where: {id:$id}
            }){
            ${this.$props.model.mutationKey} {
              id
            }
          }
        }`,
        variables: {
          id: this.tableItems[this.editedIndex].id
        }
      })
        .catch(function (er) {
          if (er.message !== 'GraphQL error: targetModel.updateMany is not a function') {
            that.error = er;
            throw (er);
          }

        })
        .then(function () {
          // that.$apollo.queries.items.refetch();
          // that.$apollo.queries.itemsConnection.refetch();
          // that.closeDelete();
          if (that.model.type === 'media') {
            that.items[0].uploads.splice(that.editedIndex, 1);
          } else {
            that.items.splice(that.editedIndex, 1);
            that.itemsConnection.aggregate.count += -1;
          }
          that.success();
        }, that.failed);
    },
    initiateState() {
      if (this.$props.model.name == "hotels" && this.$props.model.key == "golfvereines") {
        this.showUnlink = true
        this.hotels = []
        this.assignedHotelIds = []
        // fetch all available hotels
        axios
          .get(
            `${this.backend}/hotels`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.authToken,
              },
            }
          )
          .then((response) => {
            for (const hotel of response.data) {
              this.hotels.push({
                id: hotel.id,
                name: hotel.name
              })
            }
          })
          .catch((error) => {
            console.log(error);
          });

        // fetch already assigned hotels
        axios
          .get(
            `${this.backend}/golfvereines/${this.golfvereinId}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.authToken,
              },
            }
          )
          .then((response) => {
            const verein = response.data
            for (const hotel of verein.hotels) {
              this.assignedHotelIds.push(hotel.id)
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }

      if (this.$props.model.name == "hotelbuchungs" && this.$props.model.key == "competition") {
        this.showUnlink = true
        this.hidden = true
        this.referees = []
        this.assignedRefereeIds = []
        // fetch all available referees
        axios
          .get(
            `${this.backend}/users?_limit=-1&_sort=role:ASC`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.authToken,
              },
            }
          )
          .then((response) => {

            for (const referee of response.data) {
              if (referee.role && referee.role.name && ['referee','scoring'].includes(referee.role.name)) {
                this.referees.push({
                  id: referee.id,
                  name: referee.username,
                  role: referee.role.name
                })
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });

        // fetch already assigned referees
        axios
          .get(
            `${this.backend}/hotelbuchungs/?competition.id_eq=${this.competitionId}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.authToken,
              },
            }
          )
          .then((response) => {
            const hotelbuchungen = response.data
            for (const hotelbuchung of hotelbuchungen) {
              if (hotelbuchung.users_permissions_user) {
                this.assignedRefereeIds.push(hotelbuchung.users_permissions_user.id)
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    unlinkItem(item) {
      this.itemTooUnlink = item.id

      if (this.$props.model.name == "hotels" || this.$props.model.key == "golfvereines") {
        this.dialogUnlink = true
      }

      if (this.$props.model.name == "hotelbuchungs" || this.$props.model.key == "competitions") {
        this.dialogRefereeUnlink = true
      }
    },
    async unlinkItemConfirm() {
      await this.unlinkHotel(this.itemTooUnlink)
      this.dialogUnlink = false;
    },
    async unlinkRefereeItemConfirm() {
      await this.unlinkReferee(this.itemTooUnlink)
      this.dialogRefereeUnlink = false;
    },
    closeUnlink() {
      this.dialogUnlink = false;
      this.dialogRefereeUnlink = false;
      this.viewDialog = false;
      this.error = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    close() {
      this.editDialog = false
      this.importToListDialog = false
      this.editDialog = false
      this.error = null
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeAssign() {
      this.addDialog = false
      this.assignRefereeDialog = false
    },
    closeDelete() {
      this.dialogDelete = false;
      this.viewDialog = false;
      this.error = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    failed(er) {
      console.error(er);
      this.error = er.message + '\n\n';
      if (er.networkError !== undefined && er.networkError.result !== undefined && er.networkError.result.errors.length > 1) {
        for (var i = 0; i < er.networkError.result.errors.length; i++) {
          this.error += er.networkError.result.errors[i].message
        }
      }
      this.loading = false;
    },
    success() {
      this.loading = false;
      this.close();
      this.closeDelete();
      this.error = null;
    },
    async save() {
      const newData = this.generateNewDataObject();
      this.loading = true;
      try {
        let that = this;
        if (that.editedIndex > -1) {
          //FileInfoInput
          if (that.$props.model.type === 'media') {
            let updateO = {
              caption: newData.caption,
              alternativeText: newData.alternativeText ? "internal" : ""
            };

            that.$apollo.mutate({
              mutation: gql`mutation ($id: ID!, $item: FileInfoInput!) {
                updateFileInfo(
                  id: $id
                  info: $item
                ){
                  id
                }
              }`,
              variables: {
                id: that.tableItems[that.editedIndex].id,
                item: updateO
              }
            }).then(function () {
              that.$apollo.queries.items.refetch();
              that.$apollo.queries.itemsConnection.refetch();
              that.success();
            }, that.failed);
          } else {
            that.$apollo.mutate({
              mutation: gql`mutation ($id: ID!, $item: edit${that.$props.model.typeKey}Input) {
                update${that.$props.model.typeKey}(
                  input:{
                    where: {id:$id}
                    data: $item
                  }){
                  ${that.$props.model.mutationKey} {
                    id
                  }
                }
              }`,
              variables: {
                id: that.tableItems[that.editedIndex].id,
                item: newData
              }
            }).then(function () {
              that.$apollo.queries.items.refetch();
              that.$apollo.queries.itemsConnection.refetch();
              that.success();
            }, that.failed);
          }

        } else if (!this.showImportBtn) { //no campaignInputs Import
          //create record
          let tempCopy = Object.assign({}, newData);
          let createMutation = that.$props.model.createMutation || that.$props.model.mutationKey;
          let apolloObject = {};
          if (that.$props.model.type === 'media') {
            const formData = new FormData();
            tempCopy.files.map(function (x) {
              formData.append('files', x, x.name);
              return x;
            });

            //parent exists, files can be assigned
            if (tempCopy.refId) {
              formData.append('refId', tempCopy.refId);
              formData.append('ref', that.$props.parent.__typename);
              formData.append('field', 'uploads');
            }
            //creating a campaign just to upload files is to much. instead: force the user to create the campaign, before uploading files.
            /*else{


              apolloObject = {
                mutation: gqlCreator.create('Campaign','campaign',that.$props.model.model),{
                variables:{
                  item: that.$props.parent
                }
              };
              const createdCampaign =  await that.$apollo.mutate(apolloObject);

              formData.append('refId', createdCampaign.id);
              formData.append('ref', 'Campaign');
              formData.append('field', 'uploads');


            }*/

            //reset from local storage in case of user switch without reloading
            HTTP.defaults.headers['Authorization'] = localStorage.getItem('token');
            HTTP.post('/upload',
              formData
            ).then(function (re) {
              //caption was not empty must be set after upload
              if (newData.caption !== '') {

                let updateO = {
                  caption: newData.caption,
                  alternativeText: newData.alternativeText ? "internal" : ""
                };

                let updateCaption = function (files) {
                  that.$apollo.mutate({
                    mutation: gql`mutation ($id: ID!, $item: FileInfoInput!) {
                      updateFileInfo(
                        id: $id
                        info: $item
                      ){
                        id
                      }
                    }`,
                    variables: {
                      id: files[0].id,
                      item: updateO
                    }
                  }).then(function () {
                    files.shift();
                    if (files.length > 0) {
                      updateCaption(files);
                    } else {
                      that.$apollo.queries.items.refetch();
                      that.$apollo.queries.itemsConnection.refetch();
                      that.success();
                    }
                  }, that.failed);
                };
                updateCaption(re.data);
              } else {
                //no caption
                that.$nextTick(function () {
                  that.items[0] = that.items[0] || { uploads: [] };
                  that.items[0].uploads = that.items[0].uploads.concat(re.data);
                  that.itemsConnection.aggregate.count += re.data.length;
                  that.success();
                });
              }
            })
              .catch(function (er) {
                that.failed(er);
              });

          } else {
            apolloObject = {
              mutation: gqlCreator.create(that.$props.model.typeKey, createMutation, that.$props.model.model),
              variables: {
                item: tempCopy
              }
            };
            that.$apollo.mutate(apolloObject)
              .then(function (re) {
                that.$nextTick(function () {
                  if (this.$props.model.key == "golfvereine" || this.$props.model.key == "golfvereines") {
                    that.initiateState() // update state after it has been entered
                  }
                  that.items.push(re.data['create' + that.$props.model.typeKey][that.$props.model.mutationKey]);
                  that.itemsConnection.aggregate.count += 1;
                  that.success();
                })
              }, that.failed);
          }
        } else {
          //import campaign Inputs

          //get all target records
          const importAssignments = this.dataImportItem;
          //let allTargetRecords = [];
          let targetRecords = {};
          await HTTP.get(`/competitions?season=${importAssignments.season}`).then(response => {
            try {
              response.data.forEach((x) => {
                targetRecords[x.name] = {
                  id: x.id,
                  applicants: x.applicants.map(x => x.id),
                  referee_applicants: x.referee_applicants.map(x => x.id)
                }
              });
              that.success();
            } catch (er) {
              this.error = `Error: ${er.message}`;
              that.failed();
            }
          }, that.failed);

          //target records loaded
          //console.log(targetRecords);

          //go through campagn inputs and create update records array
          this.items.forEach(item => {
            const itemApplicants = item.value.Wettspiele;
            const referenceId = item['golfvereine'] ? item['golfvereine'].id : item['user'].id;
            const iDField = item['golfvereine'] ? this.dataImportItem.golfvereine : this.dataImportItem.user;
            itemApplicants.forEach(appliedFor => {
              if (targetRecords[appliedFor] && targetRecords[appliedFor][iDField].indexOf(referenceId) === -1) {
                targetRecords[appliedFor][iDField].push(referenceId);
              }
            });
          });

          let updateRecords = Object.entries(targetRecords).map(entry => {
            return {
              id: entry[1].id,
              applicants: entry[1].applicants,
              referee_applicants: entry[1].referee_applicants
            }
          });

          updateRecords.forEach(r => {
            let data = { ...r };
            delete data.id;
            HTTP.put('/competitions/' + r.id, data);
          });

          this.success();

        }
      } catch (er) {
        console.error(er);
        this.failed(er);
      }
    },
    async isAlreadyAssigned(hotelId) {
      return new Promise((resolve) => {
        axios
          .get(
            `${this.backend}/golfvereines/${this.golfvereinId}?hotels._id_eq=${hotelId}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.authToken,
              },
            }
          )
          .then(() => {
            resolve(true)
          })
          .catch(() => {
            resolve(false)
          });
      })
    },
    async isRefereeAlreadyAssigned(refereeId) {
      return new Promise((resolve) => {
        axios
          .get(
            `${this.backend}/hotelbuchungs/?competition.id_eq=${this.competitionId}&users_permissions_user.id_eq=${refereeId}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.authToken,
              },
            }
          )
          .then((result) => {
            if (result.data && !result.data.length) {
              resolve(false)
            } else {
              resolve(true)
              this.snackbarRefereeAssignedError = true
            }
          })
          .catch(() => {
            resolve(false)
          });
      })
    },
    async assignHotel(hotelId) {
      return new Promise((resolve, reject) => {
        this.assignedHotelIds.push(hotelId)
        axios
          .put(
            `${this.backend}/golfvereines/${this.golfvereinId}`,
            {
              "hotels": this.assignedHotelIds
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.authToken,
              },
            }
          )
          .then(() => {
            this.snackbarAssigned = true
            this.reload()
            resolve(true)
          })
          .catch((error) => {
            console.log(error);
            reject(error)
          });
      })
    },
    async assignReferee(refereeId) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.backend}/hotelbuchungs`,
            {
              "competition": this.competitionId,
              "users_permissions_user": refereeId,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.authToken,
              },
            }
          )
          .then(() => {
            this.snackbarRefereeAssignedSuccess = true
            this.reload()
            resolve(true)
          })
          .catch((error) => {
            console.log(error);
            reject(error)
          });
      })
    },
    async unlinkHotel(hotelId) {
      return new Promise((resolve, reject) => {
        // remove hotel id from assigned hotels
        this.assignedHotelIds = this.assignedHotelIds.filter(function (item) {
          return item !== hotelId
        })
        axios
          .put(
            `${this.backend}/golfvereines/${this.golfvereinId}`,
            {
              "hotels": this.assignedHotelIds
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.authToken,
              },
            }
          )
          .then(() => {
            this.reload()
            resolve(true)
          })
          .catch((error) => {
            console.log(error);
            reject(error)
          });
      })
    },
    async obtainRefereeId(refereeId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${this.backend}/users/?id_eq=${refereeId}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.authToken,
              },
            }
          )
          .then((response) => {
            if (response && response.data && response.data.length && response.data[0].username) {
              resolve(response.data[0].username)
            }
            else {
              reject()
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error)
          });
      })
    },
    async unlinkReferee(hotelbuchungsId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${this.backend}/hotelbuchungs/${hotelbuchungsId}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: this.authToken,
              },
            }
          )
          .then(() => {
            this.reload()
            resolve(true)
          })
          .catch((error) => {
            console.log(error);
            reject(error)
          });
      })
    },
  },
  apollo: {
    items: {
      // forgot why to skip here...
      /*skip(){
        JSON.stringify(this.where) === '{}';
      },*/
      query() {
        return gqlCreator.query(this.$props.model.model, this.cListName);
      },
      variables() {
        return { where: this.where }
      },
      update(data) {
        return data[this.cListName];
      },
    },
    itemsConnection: {
      query() {
        return gqlCreator.query('count', this.cListName);
      },
      variables() {
        return { where: this.where }
      },
      update(data) {
        return data[this.cListName + 'Connection'];
      },
    }
  }
}
</script>

<style>
.hide {
    visibility: hidden !important;
}
</style>