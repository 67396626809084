import helpers from "@/helpers/functions";
import formatMoney from "@/helpers/formatMoney";


export default (model, items, isExport) => {
  let tableList = [];
  items.forEach((x) => {
    tableList.push({});
    for (let field in x) {
      if (model[field] !== undefined) {
        const modelFields = Array.isArray(model[field]) ? model[field] : [model[field]];
        modelFields.forEach((modelField) => {
          if (typeof modelField === 'object' && modelField.visible !== false) {
            const fieldValue = modelField.content !== undefined && !!x[field] ? x[field][modelField.content] : x[field];
            //const fieldValue = modelField.content !== undefined ? getDeepestFieldValue(x[field][modelField.content],modelField) : getDeepestFieldValue(x[field],modelField);
            const fieldName = modelField.content !== undefined ? field + '.' + modelField.content : field;
            switch (modelField.type) {
              case 'date':
                tableList[tableList.length - 1][fieldName] = helpers.getLocalDateString(fieldValue);
                break;
              case 'checkbox':
                tableList[tableList.length - 1][fieldName] = fieldValue;
                break;
              case 'money':
                tableList[tableList.length - 1][fieldName] = formatMoney(fieldValue) + " €";
                break;
              case 'datetime':
                tableList[tableList.length - 1][fieldName] = helpers.getLocalDateString(fieldValue, {
                  weekday: 'short',
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit'
                });
                break;
              case 'select':
                if (typeof x[field] === 'object') {
                  if (Array.isArray(x[field])) {
                    let outA = [];
                    x[field].forEach(selectValue => {
                      outA.push(selectValue[modelField.short]);
                    });
                    tableList[tableList.length - 1][fieldName] = outA.length > 0 ? outA.join(" | ") : null;
                  } else {
                    tableList[tableList.length - 1][fieldName] = x[field] !== null ? x[field][modelField.short] : null;
                  }
                } else {
                  tableList[tableList.length - 1][fieldName] = fieldValue;
                }

                while (typeof tableList[tableList.length - 1][fieldName] === 'object' && tableList[tableList.length - 1][fieldName] !== null) {
                  tableList[tableList.length - 1][fieldName] = x[field][modelField.content][modelField.model[modelField.content].content] !== null ? x[field][modelField.content][modelField.model[modelField.content].content] : null;
                }

                break;
              case 'media':
                if (typeof fieldValue === 'object' && Array.isArray(fieldValue)) {
                  if (fieldValue.length === 0) {
                    tableList = [];
                  } else {
                    for (let m = 0; m < fieldValue.length; m++) {
                      tableList[m] = {};
                      for (let subfield in fieldValue[m]) {
                        tableList[m][subfield] = fieldValue[m][subfield];
                      }
                    }
                  }
                } else if (typeof fieldValue === 'object') {
                  //single media -> attachment
                  for (let subfield in fieldValue) {
                    tableList[0][subfield] = fieldValue[subfield];
                  }
                } else {
                  throw('unexpected input for type media');
                }
                break;
              case 'table':
              case 'list':
                if (modelField.concat_char) {
                  let concat_char = modelField.concat_char;
                  let res = '';
                  for (let ele in modelField.model) {
                    if (typeof modelField.model[ele] === 'object' && !Array.isArray(fieldValue)) {
                      for (let ele2 in modelField.model[ele]) {
                        if (fieldValue !== null && fieldValue[ele] !== null && fieldValue[ele][ele2] !== null) {
                          res += fieldValue[ele][ele2] + concat_char;
                        }
                      }
                    } else if (typeof modelField.model[ele] === 'string' && fieldValue !== null && !Array.isArray(fieldValue)) {
                      res += fieldValue[ele] + concat_char;
                    } else if (Array.isArray(fieldValue) && isExport && modelField.mutationKey === "comment") {
                      let commentRows = [];
                      fieldValue.forEach(x => {
                        commentRows.push(x.createdAt + concat_char + x.text);
                      });
                      res = commentRows.join("\n");
                    }
                    tableList[tableList.length - 1][fieldName] = res;
                  }
                }
                break;
              case 'JSON':
                //console.log(fieldValue);
                  //value key is defined in model and should be used to display content
                if (modelField.value) {
                  if (fieldValue !== null) {
                    tableList[tableList.length - 1][fieldName] = fieldValue[modelField.value];
                    //check recent entries and fill with missing context (due to multiple records of inputs)
                    let lastIndex = tableList.length - 2
                    while (lastIndex >= 0 && tableList[lastIndex][fieldName] === undefined) {
                      tableList[lastIndex][fieldName] = fieldValue[modelField.value];
                      lastIndex--;
                    }
                  }
                } else {
                  //create output string for export or table display
                  //let hadIterals = false;

                  const getStrings = (ar) => {
                    let s = [];
                    ar.forEach(x=>{
                      if(Array.isArray(x)){
                        s.push(getStrings(x));
                      }else if(typeof x === 'object'){
                        for(let oKey in x){
                          s.push(Array.isArray(x[oKey])?getStrings(x[oKey]):x[oKey]);
                        }
                      }else{
                        s.push(x);
                      }
                    });
                    return s;
                  }

                  if(isExport){
                    //create row for each iterable
                    let hadIterals = false;


                    for (let inputKey in fieldValue) {

                     //fieldContent is iterable
                     if (Array.isArray(fieldValue[inputKey])) {
                       hadIterals = true;
                       //need to check if there're other fields to enhance to each iterable row

                       for (let fKey in fieldValue) {
                         if (!Array.isArray(fieldValue[fKey])) {
                           tableList[tableList.length - 1][fKey] = fieldValue[fKey];
                         }
                       }

                       fieldValue[inputKey].forEach((inputObject, i) => {
                         if(typeof inputObject === 'object'){
                           for (let InputObjectKey in inputObject) {
                             const subFieldName = inputKey + "_" + InputObjectKey;
                             if (isExport) {
                               tableList[tableList.length - 1][subFieldName] = inputObject[InputObjectKey];
                             }
                           }
                         }else{
                           tableList[tableList.length - 1][inputKey] = inputObject;
                         }

                         if (i < fieldValue[inputKey].length - 1) {
                           tableList.push({
                             ...tableList[tableList.length - 1]
                           });
                         }
                       });
                     }
                   }

                   if (!hadIterals) {
                     for (let inputKey in fieldValue) {
                       tableList[tableList.length - 1][inputKey] = fieldValue[inputKey];
                     }
                   }
                  }
                  //no export
                  else{
                    let inputArray = [];

                    for (let inputKey in fieldValue) {
                      if (Array.isArray(fieldValue[inputKey])) {
                        inputArray = inputArray.concat(getStrings(fieldValue[inputKey]));
                      }else if(typeof fieldValue[inputKey] === 'object'){
                        const fO = fieldValue[inputKey];
                        for(let oKey in fO){
                          inputArray = inputArray.concat(Array.isArray(fO[oKey])?getStrings(fO[oKey]):[fO[oKey]]);
                        }
                      }else{
                        //input value is string
                        inputArray.push(fieldValue[inputKey]);
                      }
                    }
                    tableList[tableList.length - 1]['value'] = inputArray.join(", ");
                  }

                  /* fieldValue can be:
                  *
                    {
                      "dbfieldCheckboxGroup": [
                        "option1",
                        "option2"
                      ],
                      "ausschlussTermine": [
                        {
                          "ausschluss1": "datum1",
                          "ausschluss2": "datum2",
                          "ausschluss3": "datum3"
                        }
                      ],
                      "neu": [
                        {
                          "vorname": "nils",
                          "nachname": "pfeifenberger"
                        },
                        {
                          "vorname": "regina",
                          "nachname": "kanzler"
                        }
                      ],
                      "dbield": "texteingabe"
                    }
                    *
                    * Output should be:
                    * In Table view: value, value2...
                    * in export:
                    *
                  *
                  * */



                }

                break;
              default:
                if (fieldValue) {
                  tableList[tableList.length - 1][fieldName] = fieldValue;
                }
            }
          }else if(modelField.visible !== false){
            tableList[tableList.length-1][field] = modelField && typeof modelField.value === 'function' ? x[field](x) : x[field];
          }
        });
      }
    }
    //add helper fields (not from DB)
    for(let field in model){
      const modelFields = Array.isArray(model[field]) ? model[field] : [model[field]];
      modelFields.forEach((modelField) => {
        if (x[field] === undefined && modelField.visible !== false){
          tableList[tableList.length-1][field] = modelField && typeof modelField.value === 'function' ? modelField.value(x) : x[field];
        }
      });

    }
  });
  return tableList;
}
